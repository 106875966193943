// src/Components/Conlog.jsx
import React from "react";
import { Box, Typography, CardContent, Button, IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import placeholderImage from "../../../assets/suppliers/conlog.png"; // Replace with actual images
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const NeumorphicCard = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.85)",
  color: theme.palette.silverShades.light,
  boxShadow: `2px 2px 2px ${theme.palette.blackShades.medium}, -2px -2px 2px ${theme.palette.blackShades.light}`,
  borderRadius: "15px",
  width: "250px",
  padding: theme.spacing(2),
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    boxShadow: `5px 5px 15px ${theme.palette.blackShades.medium}, -5px -5px 15px ${theme.palette.blackShades.light}`,
    transform: "translateY(-5px)",
  },
}));

const MeterImage = styled("img")(({ theme }) => ({
  width: "150px",
  height: "auto",
  marginBottom: theme.spacing(2),
}));

function Conlog() {
  const theme = useTheme();
  const navigate=useNavigate();
  // All Conlog meters
  const meters = [
    {
      uuid: "RfoAXq",
      code: "BEC21",
      description: "Single Phase Integrated meter from Conlog.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "E2OA2z",
      code: "BEC44",
      description: "Single Phase Split meter from Conlog.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "PG2AQe",
      code: "WBEC44",
      description: "Single Phase Din Rail Split meter from Conlog.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "L1ZAPg",
      code: "IDMAPEX",
      description: "Single Phase Smart meter from Conlog.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "MJ8A6v",
      code: "WBEC42",
      description: "3-Phase Integrated meter from Conlog.",
      dataSheet: "#",
      image: placeholderImage,
    },
  ];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "rgb(0,0,0)",
        backgroundImage:
          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(20,20,20,1) 35%, rgba(41,41,41,1) 100%)",
        p: 4,
      }}
    >
<IconButton
        onClick={() => navigate("/products/suppliers")}
        sx={{
          position: "absolute",
          left: "20px",
          top: "50px",
          color: theme.palette.bronzeShades.bronze1,
          backgroundColor: "transparent",
          "&:hover": {
            color: theme.palette.bronzeShades.bronze3,
          },
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton>

      <Typography variant="h3" color="#fff" gutterBottom>
        Conlog Meters
      </Typography>
      <Typography variant="body1" color="#fff" sx={{ mb: 4, maxWidth: 600 }}>
        Check out our selection of Conlog electricity meters for reliable energy management.
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
        {meters.map((meter) => (
          <NeumorphicCard key={meter.uuid}>
            <CardContent sx={{ textAlign: "center" }}>
              <MeterImage src={meter.image} alt={meter.code} />
              <Typography variant="h6" sx={{ mb: 1 }}>
                {meter.code}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.silverShades.dark, mb: 2 }}
              >
                {meter.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href={meter.dataSheet}
                target="_blank"
                rel="noopener"
              >
                Download Data Sheet
              </Button>
            </CardContent>
          </NeumorphicCard>
        ))}
      </Box>
    </Box>
  );
}

export default Conlog;
