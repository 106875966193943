import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useMediaQuery  } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";

import backgroundImage1 from "../../../assets/bulbs/bulbs0.jpg";
import backgroundImage2 from "../../../assets/bulbs/bulbs5.jpg";
import backgroundImage3 from "../../../assets/bulbs/bulbs6.jpg";
import backgroundImage4 from "../../../assets/bulbs/bulbs8.jpg";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import RegisterButton from "./RegisterButton";


const sections = [
  { id: 0, component: <Section1 />, title: "SPECIALS", background: backgroundImage1 },
  { id: 1, component: <Section2 />, background: backgroundImage2 },
  { id: 2, component: <Section3 />, title: "About Us", background: backgroundImage3 },
  { id: 3, component: <Section4 />, title: "Get in Touch", background: backgroundImage4 },
];


const SectionContainer = styled(Box)(({ theme, background }) => ({
  boxSizing: "border-box",
  width: "100%",
  maxWidth: "100%",
  minHeight: "100vh",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backdropFilter: "blur(10px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
  paddingTop: theme.spacing(0), // Added extra top padding so the title is not cutoff
  color: "#FFF",
  textAlign: "center",
  overflowX: "hidden",
}));


const GlassCard = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  background: "rgba(255, 255, 255, 0.15)",
  backdropFilter: "blur(12px)",
  WebkitBackdropFilter: "blur(12px)",
  borderRadius: "15px",
  padding: theme.spacing(4),
  boxShadow: "0 8px 30px rgba(0, 0, 0, 0.3)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  maxWidth: "60%",
  width: "90%",
  textAlign: "center",
  margin: "0 auto",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "85%",
    width: "95%",
    padding: theme.spacing(3),
  },
}));



const WebsitePage = () => {
  const [section, setSection] = useState(0);
  const maxSections = sections.length - 1;
  const touchStartYRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const handleScroll = useCallback((event) => {
    setSection((prev) =>
      Math.min(Math.max(prev + (event.deltaY > 0 ? 1 : -1), 0), maxSections)
    );
  }, [maxSections]);

  const handleTouchStart = useCallback((event) => {
    touchStartYRef.current = event.touches[0].clientY;
  }, []);

  const handleTouchEnd = useCallback((event) => {
    if (touchStartYRef.current === null) return;
    const touchEndY = event.changedTouches[0].clientY;
    const diff = touchStartYRef.current - touchEndY;
    const threshold = 50; // Only trigger a section change if swipe distance exceeds 50px
    if (Math.abs(diff) > threshold) {
      setSection((prev) =>
        Math.min(Math.max(prev + (diff > 0 ? 1 : -1), 0), maxSections)
      );
    }
    touchStartYRef.current = null;
  }, [maxSections]);

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, { passive: true });
    return () => window.removeEventListener("wheel", handleScroll);
  }, [handleScroll]);

  return (
    <>
    {!isMobile && <RegisterButton />}
      {sections.map((section) => (
        <SectionContainer key={section.id} background={section.background}>
          {section.title && (
            <Typography variant="h2" sx={{ mb: 3, fontSize: "clamp(1.5rem, 5vw, 3rem)" }}>
              {section.title}
            </Typography>
          )}
          {section.component ? (
            section.component
          ) : (
            <GlassCard>
              <Typography variant="h5" sx={{ fontSize: "clamp(1rem, 4vw, 1.8rem)" }}>
                {section.content}
              </Typography>
            </GlassCard>
          )}
        </SectionContainer>
      ))}
    </>
  );
};

export default WebsitePage;
