// Section4.jsx
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomTextField from "../../../components/fields/CustomTextField";
import CircularProgress from "@mui/material/CircularProgress";
import { getInTouch } from "../../../redux/slices/authSlice";

const GlassmorphismContainer = styled(Box)(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.25)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  border: "1px outset rgba(255, 255, 255, 0.1)",
  padding: theme.spacing(4),
  color: theme.palette.silverShades.light,
}));

// Update NeumorphicForm in Section4.jsx:
const NeumorphicForm = styled("form")(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  boxSizing: "border-box",

  background: "rgba(255,255,255,0.02)",
  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)",
  border: "1px solid rgba(255,255,255,0.3)",
  boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",

  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",

  color: theme.palette.silverShades.light,
  borderRadius: "15px",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    margin: "0 auto",
    padding: theme.spacing(2),
  },
}));

const InlineButton = ({ onClick, text }) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      sx={{
        px: 4,
        py: theme.spacing(0.5),
        fontSize: "1rem",
        textTransform: "none",
        borderRadius: "8px",
        border: "1px solid white",
        backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.05),
        backdropFilter: "blur(6px)",
        color: theme.palette.silverShades?.light || "#ccc",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
        "&:hover": {
          backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.5),
          color: theme.palette.common.white,
          boxShadow: "1px 1px 16px rgba(255, 255, 255, 1)",
        },
      }}
    >
      {text}
    </Button>
  );
};

const Section4 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    surname: "",
    email: "",
    cellNr: "",
    query: "",
  });

  const validateEmail = (email) => {
    const emailRegex = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+' + // local part (unquoted)
        '(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)' + // dot-atom part
        '|(\\".+\\"))@' + // or quoted local part
        "((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\." +
        "[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+" + // domain part
        "[a-zA-Z]{2,}))$"
    );
    return emailRegex.test(email);
  };
  const validateCellNr = (cellNr) => {
    const cellRegex = /^(0\d{9}|(\27|27)\d{9})$/;
    return cellNr.match(cellRegex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return; 
    setIsLoading(true);
    if (!formData.firstName.trim() || !formData.surname.trim()) {
      window.alert("Please fill in your first name and surname.");
      setIsLoading(false);
      return;
    }
    if (!formData.email.trim() && !formData.cellNr.trim()) {
      window.alert("Please provide either an email address or a cell number.");
      setIsLoading(false);
      return;
    }

    // If the user did enter an email, validate it
    if (formData.email.trim()) {
      if (!validateEmail(formData.email)) {
        window.alert(`Please enter a valid email address. ${formData.email}`);
        setIsLoading(false);
        return;
      }
    }

    // If the user did enter a cell number, validate it
    if (formData.cellNr.trim()) {
      if (!validateCellNr(formData.cellNr)) {
        window.alert(`Please enter a valid South African cell number. ${formData.cellNr}`);
        setIsLoading(false);
        return;
      }
    }

    try {
      await dispatch(getInTouch(formData));
      setPopupOpen(true);
      setFormData({
        firstName: "",
        surname: "",
        email: "",
        cellNr: "",
        query: "",
      });
    } catch {
      window.alert("Submission failed. Please try again.");
    }
    finally{
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: "70vh", // Changed from "100vh" to "70vh"
        width: "100%",
        padding: theme.spacing(4),
        background: "transparent",
        padding: isMobile ? theme.spacing(1) : 0,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: isMobile ? "5%" : "18%",
          left: "4%",
          right: "4%",
          bottom: "10%",
          display: "flex",
          flexDirection: "column",
          gap: isMobile ? theme.spacing(1) : theme.spacing(3),
        }}
      >
        {isMobile ? (
          // Mobile: Only show the form with reduced spacing and full width
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflowX: "hidden",
            }}
          >
            <NeumorphicForm>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomTextField fullWidth label="First Name" variant="outlined" name="firstName" value={formData.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField fullWidth label="Surname" variant="outlined" name="surname" value={formData.surname} onChange={(e) => setFormData({ ...formData, surname: e.target.value })} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField fullWidth label="Email" variant="outlined" name="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField fullWidth label="Cell Nr" variant="outlined" name="cellNr" value={formData.cellNr} onChange={(e) => setFormData({ ...formData, cellNr: e.target.value })} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField fullWidth label="Query" variant="outlined" name="query" value={formData.query} onChange={(e) => setFormData({ ...formData, query: e.target.value })} />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                                  <InlineButton
                    onClick={handleSubmit}
                    text={
                      isLoading ? (
                       <CircularProgress size={22} sx={{ color: theme.palette.common.white }} />
                      ) : (
                        "Submit"
                      )
                    }
                  />
                </Grid>
              </Grid>
            </NeumorphicForm>
          </Box>
        ) : (
          // Desktop: Left info column and right form column
          <Box sx={{ display: "flex", gap: theme.spacing(4), flex: 1 }}>
            <GlassmorphismContainer sx={{ flex: 1 }}>
              <Typography variant="h4" gutterBottom>
                Get in Touch
              </Typography>
              <Box sx={{ mt: 2 }}>
                {["Competitive Channel Fees", "Swift Response Time", "Customized Solutions", "24hr Customized Reports Turnaround Time"].map((text, index) => (
                  <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CheckCircleIcon sx={{ color: theme.palette.bronzeShades?.bronze1 || "#CD7F32", mr: 1 }} />
                    <Typography variant="body1">{text}</Typography>
                  </Box>
                ))}
              </Box>
            </GlassmorphismContainer>
            <Box sx={{ flex: 2 }}>
              <NeumorphicForm>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField fullWidth label="First Name" variant="outlined" name="firstName" value={formData.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField fullWidth label="Surname" variant="outlined" name="surname" value={formData.surname} onChange={(e) => setFormData({ ...formData, surname: e.target.value })} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField fullWidth label="Email" variant="outlined" name="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField fullWidth label="Cell Nr" variant="outlined" name="cellNr" value={formData.cellNr} onChange={(e) => setFormData({ ...formData, cellNr: e.target.value })} />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField fullWidth label="Query" variant="outlined" name="query" value={formData.query} onChange={(e) => setFormData({ ...formData, query: e.target.value })} />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <InlineButton
                    onClick={handleSubmit}
                    text={
                      isLoading ? (
                       <CircularProgress size={22} sx={{ color: theme.palette.common.white }} />
                      ) : (
                        "Submit"
                      )
                    }
                  />
                  </Grid>
                </Grid>
              </NeumorphicForm>
            </Box>
          </Box>
        )}
      </Box>

      {isMobile ? (
        <Snackbar open={popupOpen} autoHideDuration={6000} onClose={() => setPopupOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{ transform: "translateY(50%)" }}>
          <Alert
            onClose={() => setPopupOpen(false)}
            severity="success"
            sx={{
              width: "100%",
              fontSize: "1.1rem",
              textAlign: "center",
            }}
          >
            Thank you for your query. Meterminds will be in touch with you via whatsapp/email.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar open={popupOpen} autoHideDuration={6000} onClose={() => setPopupOpen(false)}>
          <Alert onClose={() => setPopupOpen(false)} severity="success" sx={{ width: "100%" }}>
            Thank you for your query. Meterminds will be in touch with you via whatsapp/email.
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Section4;
