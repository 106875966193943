import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography, TextField } from "@mui/material";
import LoaderButton from "../../../components/buttons/LoaderButton";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import { editArrearsFee, deleteArrearsFee } from "../../../redux/slices/tenantManagementSlice";

const EditArrearsFee = ({ tenant_uuid, arrears_fee, onBack, heading }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    arrears_percentage: false,
    arrears_percentage_amount: "",
    arrears_fixed: false,
    arrears_fixed_amount: "",
    arrears_paid_amount: "",
    arrears_outstanding_amount: "",
    customer_arrears_fee_verified: false,
    customer_arrears_fee_verified_message: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (arrears_fee) {
      setFormData({
        arrears_percentage: arrears_fee.arrears_percentage || false,
        arrears_percentage_amount: arrears_fee.arrears_percentage_amount || "",
        arrears_fixed: arrears_fee.arrears_fixed || false,
        arrears_fixed_amount: arrears_fee.arrears_fixed_amount || "",
        arrears_paid_amount: arrears_fee.arrears_paid_amount || "",
        arrears_outstanding_amount: arrears_fee.arrears_outstanding_amount || "",
        customer_arrears_fee_verified: arrears_fee.customer_arrears_fee_verified || false,
        customer_arrears_fee_verified_message: arrears_fee.customer_arrears_fee_verified_message || "",
      });
    }
  }, [arrears_fee]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const handleValidation = () => {
    const newErrors = {};
    if (!formData.arrears_percentage_amount && !formData.arrears_fixed_amount) {
      newErrors.amount = "Either percentage or fixed amount must be provided.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) return;

    try {
      await dispatch(editArrearsFee({ tenant_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating fixed fee:", error);
    } finally {
      onBack();
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteArrearsFee({ tenant_uuid })).unwrap();
      onBack();
    } catch (error) {
      console.error("Error deleting fixed fee:", error);
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            {heading}
          </Button>
          <Typography variant="h3">Edit Arrears Fee</Typography>
        </ColumnHeading>
      </Row>
      <Row>
        <ColumnInset xs={6}>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Arrears Percentage</InputLabel>
            <Select name="arrears_percentage" value={formData.arrears_percentage} onChange={handleChange}>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
          <TextField label="Arrears Percentage Amount" name="arrears_percentage_amount" type="number" value={formData.arrears_percentage_amount} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Arrears Fixed</InputLabel>
            <Select name="arrears_fixed" value={formData.arrears_fixed} onChange={handleChange}>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
          <TextField label="Arrears Fixed Amount" name="arrears_fixed_amount" type="number" value={formData.arrears_fixed_amount} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />
          <TextField label="Paid Amount" name="arrears_paid_amount" type="number" value={formData.arrears_paid_amount} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />
          <TextField label="Outstanding Amount" name="arrears_outstanding_amount" type="number" value={formData.arrears_outstanding_amount} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Customer Fee Verified</InputLabel>
            <Select name="customer_arrears_fee_verified" value={formData.customer_arrears_fee_verified} onChange={handleChange}>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
          <TextField label="Verification Message" name="customer_arrears_fee_verified_message" value={formData.customer_arrears_fee_verified_message} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <LoaderButton buttonText="Delete" onClick={handleDelete} color="error" />
            <LoaderButton buttonText="Cancel" onClick={onBack} />
          </Box>
        </ColumnInset>
      </Row>
    </Container>
  );
};

export default EditArrearsFee;
