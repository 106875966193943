import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import VendingSolution from "./VendingSolution";
import VendingSolutionMobile from "./VendingSolutionMobile";

const VendingSolutionWrapper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Create dummy refs to provide a valid Outlet context.
  const contentRef = useRef(null);
  const sentinelRef = useRef(null);

  return (
    <div>
      {isMobile ? <VendingSolutionMobile /> : <VendingSolution />}
      {/* This Outlet will render nested routes if any, and provides the expected context */}
      <Outlet context={{ contentRef, sentinelRef }} />
    </div>
  );
};

export default VendingSolutionWrapper;
