import React, { useState } from "react";
import { Box, TextField, Checkbox, Button, Typography, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { registerCompany } from "../../../redux/slices/authSlice";
import AddressSearch from "./AddressSearchMobile"; // Make sure this is your revamped AddressSearch component
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
// (Optionally) if you have a neumorphic styled TextField defined elsewhere,
// you can import it. For this example, we use a local definition:
import { styled } from "@mui/material/styles";
const NeumorphicTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#e0e0e0",
  borderRadius: "8px",
  boxShadow: "inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "#ccc" },
    "&:hover fieldset": { borderColor: "#aaa" },
    "&.Mui-focused fieldset": { borderColor: "#aaa" },
  },
  "& .MuiInputLabel-root": { color: "#333", fontSize: "1.4rem" },
  "& .MuiInputBase-input": { color: "#333", fontSize: "1.4rem" },
}));

const RegisterCompanyMobile = ({ onBackToUserTypeSelection }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const navigate = useNavigate(); // if not already declared
  const [activeStep, setActiveStep] = useState(0);
  // addressCount stored as a string for input flexibility
  const [addressCount, setAddressCount] = useState("1");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    company: {
      companyName: "",
      companyType: "",
      vatNumber: "",
      name: "",
      surname: "",
      idNumber: "",
      email: "",
      cell: "",
      hasRepresentative: false,
    },
    representative: {
      name: "",
      surname: "",
      idNumber: "",
      email: "",
      cell: "",
    },
    addresses: [
      {
        complex_name: "",
        complex_address1: "",
        complex_address2: "",
        complex_postalcode: "",
        complex_province: "",
      },
    ],
    documents: {
      municipalBill: [],
      idPassport: [],
      representativeIdPassport: [],
      companyRegistration: [],
      bankLetter: [],
    },
    termsAccepted: false,
  });

  // Helper functions
  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (section, field, value) => {
    setFormData((prev) => ({ ...prev, [section]: { ...prev[section], [field]: value } }));
  };

  const handleAddressChange = (index, field, value) => {
    const newAddresses = [...formData.addresses];
    newAddresses[index] = { ...newAddresses[index], [field]: value };
    setFormData((prev) => ({ ...prev, addresses: newAddresses }));
  };

  const handleFileChange = (e, docType) => {
    const files = e.target.files;
    setFormData((prev) => ({
      ...prev,
      documents: { ...prev.documents, [docType]: files },
    }));
    setErrors((prev) => ({
      ...prev,
      documents: { ...prev.documents, [docType]: "" },
    }));
  };

  // Calculate step boundaries dynamically.
  const numAddresses = parseInt(addressCount, 10) || 1;
  const hasRep = formData.company.hasRepresentative;
  const companyStep = 0;
  const representativeStep = hasRep ? 1 : -1; // if not used, skip rep step
  const startAddressStep = hasRep ? 2 : 1;
  const endAddressStep = startAddressStep + numAddresses - 1;
  const docsStep = endAddressStep + 1;
  const termsStep = docsStep + 1;
  const totalSteps = termsStep + 1; // zero-indexed steps

  // Validation function for each step:
  const validateStep = (step) => {
    const newErrors = {};
    let valid = true;
    if (step === companyStep) {
      // Validate company details
      if (!formData.company.companyName) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), companyName: "Company Name is required" };
      }
      if (!formData.company.companyType) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), companyType: "Company Type is required" };
      }
      if (!formData.company.vatNumber) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), vatNumber: "VAT Number is required" };
      }
      if (!formData.company.name) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), name: "Contact Name is required" };
      }
      if (!formData.company.surname) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), surname: "Contact Surname is required" };
      }
      if (!formData.company.idNumber) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), idNumber: "Contact ID Number is required" };
      }
      if (!formData.company.email) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), email: "Contact Email is required" };
      }
      if (!formData.company.cell) {
        valid = false;
        newErrors.company = { ...(newErrors.company || {}), cell: "Contact Cell is required" };
      }
    } else if (step === representativeStep) {
      // Validate representative details (if applicable)
      if (!formData.representative.name) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), name: "Representative Name is required" };
      }
      if (!formData.representative.surname) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), surname: "Representative Surname is required" };
      }
      if (!formData.representative.idNumber) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), idNumber: "Representative ID Number is required" };
      }
      if (!formData.representative.email) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), email: "Representative Email is required" };
      }
      if (!formData.representative.cell) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), cell: "Representative Cell is required" };
      }
    } else if (step >= startAddressStep && step <= endAddressStep) {
      // Validate address fields
      const addressIndex = step - startAddressStep;
      const address = formData.addresses[addressIndex] || {};
      if (!address.complex_address1) {
        valid = false;
        newErrors.addresses = newErrors.addresses || [];
        newErrors.addresses[addressIndex] = {
          ...(newErrors.addresses[addressIndex] || {}),
          complex_address1: "Address is required",
        };
      }
      if (!address.complex_name) {
        valid = false;
        newErrors.addresses = newErrors.addresses || [];
        newErrors.addresses[addressIndex] = {
          ...(newErrors.addresses[addressIndex] || {}),
          complex_name: "Complex Name is required",
        };
      }
    } else if (step === docsStep) {
      // Validate required document uploads
      if (!formData.documents.municipalBill || formData.documents.municipalBill.length === 0) {
        valid = false;
        newErrors.documents = { ...(newErrors.documents || {}), municipalBill: "Municipal Bill is required" };
      }
      if (!formData.documents.idPassport || formData.documents.idPassport.length === 0) {
        valid = false;
        newErrors.documents = { ...(newErrors.documents || {}), idPassport: "ID/Passport is required" };
      }
      if (formData.company.hasRepresentative) {
        if (!formData.documents.representativeIdPassport || formData.documents.representativeIdPassport.length === 0) {
          valid = false;
          newErrors.documents = {
            ...(newErrors.documents || {}),
            representativeIdPassport: "Representative ID/Passport is required",
          };
        }
      }
      if (!formData.documents.companyRegistration || formData.documents.companyRegistration.length === 0) {
        valid = false;
        newErrors.documents = { ...(newErrors.documents || {}), companyRegistration: "Company Registration is required" };
      }
      if (!formData.documents.bankLetter || formData.documents.bankLetter.length === 0) {
        valid = false;
        newErrors.documents = { ...(newErrors.documents || {}), bankLetter: "Bank Letter is required" };
      }
    } else if (step === termsStep) {
      if (!formData.termsAccepted) {
        valid = false;
        newErrors.terms = "You must accept the terms and conditions";
      }
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep(activeStep)) {
      if (activeStep === totalSteps - 1) {
        setIsSubmitting(true);
        const submissionData = new FormData();
        submissionData.append("company_companyName", formData.company.companyName);
        submissionData.append("company_companyType", formData.company.companyType);
        submissionData.append("company_vatNumber", formData.company.vatNumber);
        submissionData.append("company_name", formData.company.name);
        submissionData.append("company_surname", formData.company.surname);
        submissionData.append("company_idNumber", formData.company.idNumber);
        submissionData.append("company_email", formData.company.email);
        submissionData.append("company_cell", formData.company.cell);
        submissionData.append("company_hasRepresentative", formData.company.hasRepresentative);
        if (formData.company.hasRepresentative) {
          submissionData.append("representative_name", formData.representative.name);
          submissionData.append("representative_surname", formData.representative.surname);
          submissionData.append("representative_idNumber", formData.representative.idNumber);
          submissionData.append("representative_email", formData.representative.email);
          submissionData.append("representative_cell", formData.representative.cell);
        }
        formData.addresses.forEach((address, index) => {
          submissionData.append(`addresses_${index}_complex_name`, address.complex_name);
          submissionData.append(`addresses_${index}_complex_address1`, address.complex_address1);
          submissionData.append(`addresses_${index}_complex_address2`, address.complex_address2);
          submissionData.append(`addresses_${index}_complex_postalcode`, address.complex_postalcode);
          submissionData.append(`addresses_${index}_complex_province`, address.complex_province);
        });
        Array.from(formData.documents.municipalBill).forEach((file) => {
          submissionData.append("documents.municipalBill", file);
        });
        Array.from(formData.documents.idPassport).forEach((file) => {
          submissionData.append("documents.idPassport", file);
        });
        if (formData.company.hasRepresentative) {
          Array.from(formData.documents.representativeIdPassport).forEach((file) => {
            submissionData.append("documents.representativeIdPassport", file);
          });
        }
        Array.from(formData.documents.companyRegistration).forEach((file) => {
          submissionData.append("documents.companyRegistration", file);
        });
        Array.from(formData.documents.bankLetter).forEach((file) => {
          submissionData.append("documents.bankLetter", file);
        });
        submissionData.append("termsAccepted", formData.termsAccepted);

        console.log(formData);

        dispatch(registerCompany(submissionData))
          .unwrap()
          .then(() => {
            setIsSubmitting(false);
            setSubmissionStatus("success");
          })
          .catch((error) => {
            console.error("Registration failed:", error);
            setIsSubmitting(false);
            setSubmissionStatus("failure");
          });
      } else {
        handleNext();
      }
    }
  };

  let content;
  // Step 0: Company Details
  if (activeStep === companyStep) {
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <NeumorphicTextField label="Company Name" value={formData.company.companyName} onChange={(e) => handleChange("company", "companyName", e.target.value)} fullWidth margin="dense" error={!!errors.company?.companyName} helperText={errors.company?.companyName} />
        <NeumorphicTextField label="Company Type" value={formData.company.companyType} onChange={(e) => handleChange("company", "companyType", e.target.value)} fullWidth margin="dense" error={!!errors.company?.companyType} helperText={errors.company?.companyType} />
        <NeumorphicTextField label="VAT Number" value={formData.company.vatNumber} onChange={(e) => handleChange("company", "vatNumber", e.target.value)} fullWidth margin="dense" error={!!errors.company?.vatNumber} helperText={errors.company?.vatNumber} />
        <NeumorphicTextField label="Contact Name" value={formData.company.name} onChange={(e) => handleChange("company", "name", e.target.value)} fullWidth margin="dense" error={!!errors.company?.name} helperText={errors.company?.name} />
        <NeumorphicTextField label="Contact Surname" value={formData.company.surname} onChange={(e) => handleChange("company", "surname", e.target.value)} fullWidth margin="dense" error={!!errors.company?.surname} helperText={errors.company?.surname} />
        <NeumorphicTextField label="Contact ID Number" value={formData.company.idNumber} onChange={(e) => handleChange("company", "idNumber", e.target.value)} fullWidth margin="dense" error={!!errors.company?.idNumber} helperText={errors.company?.idNumber} />
        <NeumorphicTextField label="Contact Email" value={formData.company.email} onChange={(e) => handleChange("company", "email", e.target.value)} fullWidth margin="dense" error={!!errors.company?.email} helperText={errors.company?.email} />
        <NeumorphicTextField label="Contact Cell" value={formData.company.cell} onChange={(e) => handleChange("company", "cell", e.target.value)} fullWidth margin="dense" error={!!errors.company?.cell} helperText={errors.company?.cell} />
        <FormControlLabel control={<Checkbox checked={formData.company.hasRepresentative} onChange={(e) => handleChange("company", "hasRepresentative", e.target.checked)} sx={{ "& .MuiSvgIcon-root": { fontSize: "1.8rem", color: "#333" } }} />} label={<Typography sx={{ fontSize: "1.4rem", color: "#333" }}>Has Representative</Typography>} />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={onBackToUserTypeSelection} variant="outlined" sx={{ fontSize: "1.4rem" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.4rem" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (hasRep && activeStep === representativeStep) {
    // Representative Details Step (if applicable)
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <NeumorphicTextField label="Representative Name" value={formData.representative.name} onChange={(e) => handleChange("representative", "name", e.target.value)} fullWidth margin="dense" error={!!errors.representative?.name} helperText={errors.representative?.name} />
        <NeumorphicTextField label="Representative Surname" value={formData.representative.surname} onChange={(e) => handleChange("representative", "surname", e.target.value)} fullWidth margin="dense" error={!!errors.representative?.surname} helperText={errors.representative?.surname} />
        <NeumorphicTextField label="Representative ID Number" value={formData.representative.idNumber} onChange={(e) => handleChange("representative", "idNumber", e.target.value)} fullWidth margin="dense" error={!!errors.representative?.idNumber} helperText={errors.representative?.idNumber} />
        <NeumorphicTextField label="Representative Email" value={formData.representative.email} onChange={(e) => handleChange("representative", "email", e.target.value)} fullWidth margin="dense" error={!!errors.representative?.email} helperText={errors.representative?.email} />
        <NeumorphicTextField label="Representative Cell" value={formData.representative.cell} onChange={(e) => handleChange("representative", "cell", e.target.value)} fullWidth margin="dense" error={!!errors.representative?.cell} helperText={errors.representative?.cell} />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.4rem" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.4rem" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (activeStep >= startAddressStep && activeStep <= endAddressStep) {
    // Address Steps: Use AddressSearch component.
    const addressIndex = activeStep - startAddressStep;
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        {addressIndex === 0 && (
          <NeumorphicTextField
            label="Number of Addresses"
            type="number"
            value={addressCount}
            onChange={(e) => {
              const input = e.target.value;
              setAddressCount(input);
              const count = parseInt(input, 10);
              if (!isNaN(count) && count > 0) {
                const addresses = [];
                for (let i = 0; i < count; i++) {
                  addresses.push({
                    complex_name: "",
                    complex_address1: "",
                    complex_address2: "",
                    complex_postalcode: "",
                    complex_province: "",
                  });
                }
                setFormData((prev) => ({ ...prev, addresses }));
              }
            }}
            fullWidth
            margin="dense"
            error={!!errors.addresses?.[addressIndex]?.number}
            helperText={errors.addresses?.[addressIndex]?.number}
          />
        )}
        <Typography sx={{ fontSize: "1.4rem", mt: 1, mb: 1, color: "#333" }}>
          Address {addressIndex + 1} of {numAddresses}
        </Typography>
        <AddressSearch currentAddressIndex={addressIndex} formData={formData} setFormData={setFormData} errors={errors} handleChange={(e, section, field) => handleAddressChange(addressIndex, field, e.target.value)} />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.4rem" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.4rem" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (activeStep === docsStep) {
    // Documents Step
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" sx={{ fontSize: "1.6rem", mb: 2, color: "#333" }}>
          Upload Documents
        </Typography>
        <Box display="flex" alignItems="center">
          <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
            Municipal Bill
            <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "municipalBill")} />
          </Button>
          {formData.documents.municipalBill && formData.documents.municipalBill.length > 0 && <span style={{ marginLeft: "8px", color: "green", fontSize: "2rem" }}>✓</span>}
        </Box>
        {errors.documents?.municipalBill && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.documents.municipalBill}
          </Typography>
        )}
        <Box display="flex" alignItems="center">
          <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
            ID/Passport
            <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "idPassport")} />
          </Button>
          {formData.documents.idPassport && formData.documents.idPassport.length > 0 && <span style={{ marginLeft: "8px", color: "green", fontSize: "2rem" }}>✓</span>}
        </Box>
        {errors.documents?.idPassport && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.documents.idPassport}
          </Typography>
        )}
        {formData.company.hasRepresentative && (
          <>
            <Box display="flex" alignItems="center">
              <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
                Representative ID/Passport
                <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "representativeIdPassport")} />
              </Button>
              {formData.documents.representativeIdPassport && formData.documents.representativeIdPassport.length > 0 && <span style={{ marginLeft: "8px", color: "green", fontSize: "2rem" }}>✓</span>}
            </Box>
            {errors.documents?.representativeIdPassport && (
              <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
                {errors.documents.representativeIdPassport}
              </Typography>
            )}
          </>
        )}
        <Box display="flex" alignItems="center">
          <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
            Company Reg
            <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "companyRegistration")} />
          </Button>
          {formData.documents.companyRegistration && formData.documents.companyRegistration.length > 0 && <span style={{ marginLeft: "8px", color: "green", fontSize: "2rem" }}>✓</span>}
        </Box>
        {errors.documents?.companyRegistration && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.documents.companyRegistration}
          </Typography>
        )}
        <Box display="flex" alignItems="center">
          <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
            Bank Letter
            <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "bankLetter")} />
          </Button>
          {formData.documents.bankLetter && formData.documents.bankLetter.length > 0 && <span style={{ marginLeft: "8px", color: "green", fontSize: "2rem" }}>✓</span>}
        </Box>
        {errors.documents?.bankLetter && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.documents.bankLetter}
          </Typography>
        )}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.4rem" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.4rem" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (activeStep === termsStep) {
    // Terms Step
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <FormControlLabel control={<Checkbox checked={formData.termsAccepted} onChange={(e) => setFormData((prev) => ({ ...prev, termsAccepted: e.target.checked }))} sx={{ "& .MuiSvgIcon-root": { fontSize: "1.8rem", color: "#333" } }} />} label={<Typography sx={{ fontSize: "1.4rem", color: "#333" }}>I accept the terms and conditions</Typography>} />
        {errors.terms && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.terms}
          </Typography>
        )}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.4rem" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.4rem" }}>
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Box>
    );
  } else {
    content = <Typography sx={{ fontSize: "1.4rem", color: "#333" }}>Unknown step. Please check your input.</Typography>;
  }

  return (
    <Box sx={{ mt: 8, p: 1, backgroundColor: "#e0e0e0", borderRadius: "16px", boxShadow: "inset 8px 8px 16px #bebebe, inset -8px -8px 16px #ffffff" }}>
      <Typography variant="h5" sx={{ fontSize: "1.8rem", mb: 1, color: "#333" }}>
        Register Company
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "1.2rem", mb: 1, color: "#333" }}>
        Step {activeStep + 1} of {totalSteps}
      </Typography>
      {content}
      {submissionStatus !== "" && (
        <Dialog open={true} onClose={() => {}}>
          <DialogTitle sx={{ fontSize: "2rem", fontWeight: "bold" }}>{submissionStatus === "success" ? "Registration Successful" : "Registration Failed"}</DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: "1.6rem" }}>{submissionStatus === "success" ? "Please check your email for further instructions." : "There was an error with your registration. Please try again."}</Typography>
          </DialogContent>
          <DialogActions>
            {submissionStatus === "failure" ? (
              <Button
                onClick={() => {
                  setSubmissionStatus("");
                  setActiveStep(termsStep);
                }}
                color="primary"
                sx={{ fontSize: "1.4rem" }}
              >
                Back
              </Button>
            ) : (
              <Button onClick={() => navigate("/")} color="primary" sx={{ fontSize: "1.4rem" }}>
                Continue
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default RegisterCompanyMobile;
