import React, { useState } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { registerAdmin } from "../../../redux/slices/authSlice"; // Adjust this import as needed
import { styled } from "@mui/material/styles";

// Define a neumorphic-styled TextField
const NeumorphicTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#e0e0e0",
  borderRadius: "8px",
  boxShadow: "inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "#ccc" },
    "&:hover fieldset": { borderColor: "#aaa" },
    "&.Mui-focused fieldset": { borderColor: "#aaa" },
  },
  "& .MuiInputLabel-root": { color: "#333", fontSize: "1.4rem" },
  "& .MuiInputBase-input": { color: "#333", fontSize: "1.4rem" },
}));

const RegisterAdminMobile = ({ onBackToUserTypeSelection }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // Single-step admin form; all fields are in one step
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    meterminds_email: "",
    cell: "",
    id: "",
    authorizationKey: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  // Validate that every field is populated
  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.username) {
      valid = false;
      newErrors.username = "Username is required";
    }
    if (!formData.password) {
      valid = false;
      newErrors.password = "Password is required";
    }
    if (!formData.email) {
      valid = false;
      newErrors.email = "Email is required";
    }
    if (!formData.meterminds_email) {
      valid = false;
      newErrors.meterminds_email = "Meterminds Email is required";
    }
    if (!formData.cell) {
      valid = false;
      newErrors.cell = "Cell is required";
    }
    if (!formData.id) {
      valid = false;
      newErrors.id = "ID is required";
    }
    if (!formData.authorizationKey) {
      valid = false;
      newErrors.authorizationKey = "Authorization Key is required";
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const submissionData = new FormData();
      submissionData.append("username", formData.username);
      submissionData.append("password", formData.password);
      submissionData.append("email", formData.email);
      submissionData.append("meterminds_email", formData.meterminds_email);
      submissionData.append("cell", formData.cell);
      submissionData.append("id", formData.id);
      submissionData.append("authorizationKey", formData.authorizationKey);

      dispatch(registerAdmin(submissionData))
        .unwrap()
        .then(() => {
          // Optionally, you can advance to a confirmation step or navigate away.
        })
        .catch((error) => {
          console.error("Registration failed:", error);
        });
    }
  };

  return (
    <Box
      sx={{
        mt: 8,
        mb: 2,
        p: 2,
        backgroundColor: "#e0e0e0",
        borderRadius: "16px",
        boxShadow: "inset 8px 8px 16px #bebebe, inset -8px -8px 16px #ffffff",
      }}
    >
      <Typography variant="h5" sx={{ fontSize: "1.8rem", mb: 2, color: "#333" }}>
        Register Admin
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <NeumorphicTextField label="Username" value={formData.username} onChange={(e) => handleChange("username", e.target.value)} fullWidth margin="dense" error={!!errors.username} helperText={errors.username} />
        <NeumorphicTextField label="Password" type="password" value={formData.password} onChange={(e) => handleChange("password", e.target.value)} fullWidth margin="dense" error={!!errors.password} helperText={errors.password} />
        <NeumorphicTextField label="Email" value={formData.email} onChange={(e) => handleChange("email", e.target.value)} fullWidth margin="dense" error={!!errors.email} helperText={errors.email} />
        <NeumorphicTextField label="Meterminds Email" value={formData.meterminds_email} onChange={(e) => handleChange("meterminds_email", e.target.value)} fullWidth margin="dense" error={!!errors.meterminds_email} helperText={errors.meterminds_email} />
        <NeumorphicTextField label="Cell" value={formData.cell} onChange={(e) => handleChange("cell", e.target.value)} fullWidth margin="dense" error={!!errors.cell} helperText={errors.cell} />
        <NeumorphicTextField label="ID" value={formData.id} onChange={(e) => handleChange("id", e.target.value)} fullWidth margin="dense" error={!!errors.id} helperText={errors.id} />
        <NeumorphicTextField label="Authorization Key" value={formData.authorizationKey} onChange={(e) => handleChange("authorizationKey", e.target.value)} fullWidth margin="dense" error={!!errors.authorizationKey} helperText={errors.authorizationKey} />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            onClick={onBackToUserTypeSelection}
            variant="outlined"
            sx={{
              fontSize: "1.4rem",
              color: "#333",
              borderColor: "#ccc",
            }}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              fontSize: "1.4rem",
              backgroundColor: "#e0e0e0",
              color: "#333",
              boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff",
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterAdminMobile;
