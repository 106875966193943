// src/components/website/ScrollProgressBar.jsx

import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

function ScrollProgressBar({ contentRef }) {
  const [scrollProgress, setScrollProgress] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const scrollElement = contentRef && contentRef.current ? contentRef.current : document.body;

    if (!scrollElement) return;

    const handleScroll = () => {
      const totalScrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
      const currentScroll = scrollElement.scrollTop;

      if (totalScrollHeight > 0) {
        const scrollPercentage = (currentScroll / totalScrollHeight) * 100;
        setScrollProgress(scrollPercentage);
      } else {
        setScrollProgress(0);
      }
    };

    scrollElement.addEventListener("scroll", handleScroll);

    // Cleanup on unmount
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [contentRef]);

  const totalSegments = isSmallScreen ? 5 : 10;
  const filledSegments = Math.round((scrollProgress / 100) * totalSegments);

  return (
    <Box
      role="progressbar"
      aria-valuenow={scrollProgress}
      aria-valuemin={0}
      aria-valuemax={100}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        maxWidth: "300px",
        // Removed margin and padding
      }}
    >
      {Array.from({ length: totalSegments }).map((_, index) => (
        <Box
          key={index}
          sx={{
            flexGrow: 1,
            height: "10px",
            margin: "0 2px",
            backgroundColor: index < filledSegments ? theme.palette.silverShades.light : theme.palette.blackShades.black,
            borderRadius: "5px",
            transition: "background-color 0.3s ease",
          }}
        />
      ))}
    </Box>
  );
}
ScrollProgressBar.propTypes = {
  contentRef: PropTypes.object.isRequired, // Define propTypes
};

export default ScrollProgressBar;
