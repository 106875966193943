import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box, Typography, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openLoginModal } from "../../../redux/slices/uiSlice";
import { logout } from "../../../redux/slices/authSlice";
import ServicesBarMobile from "./ServicesBarMobile";
import ProductsBarMobile from "./ProductsBarMobile";

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.blackShades.black}, ${theme.palette.blackShades.dark})`,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: theme.zIndex.appBar + 100,
}));

const MobileNavLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.silverShades.light,
}));

function TopNavBarMobile() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);

  const handleLoginOpen = () => dispatch(openLoginModal());
  const handleLogout = () => dispatch(logout());

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;
    setDrawerOpen(open);
  };

  const handleServicesBack = () => {
    setServicesOpen(false);
    setDrawerOpen(true);
  };

  const handleProductsBack = () => {
    setProductsOpen(false);
    setDrawerOpen(true);
  };

  const navItems = [
    { label: "Home", link: "/" },
    { label: "Services", isDropdown: true },
    { label: "Products", isDropdown: true },
    { label: "Recharge", link: "/services/recharge" },
    { label: "Register", link: "/register" },
  ];

  return (
    <>
      <GradientAppBar position="fixed">
        <Toolbar>
          <Typography
            variant="h4"
            sx={{
              flexGrow: 1,
              color: theme.palette.silverShades.light,
              fontSize: { xs: "1.8rem", sm: "2.2rem", md: "2.6rem" },
            }}
          >
            Meter Minds
          </Typography>
          <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)} aria-label="menu">
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </GradientAppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            background: "rgba(255,255,255,0.1)",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            border: "1px solid rgba(255,255,255,0.3)",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
          },
        }}
      >
        <Box sx={{ width: 250, padding: theme.spacing(2) }} role="presentation">
          <List>
            {navItems.map((item, index) =>
              item.isDropdown ? (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    if (item.label === "Services") {
                      setServicesOpen(true);
                    } else if (item.label === "Products") {
                      setProductsOpen(true);
                    }
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: theme.palette.silverShades.light,
                      },
                    }}
                  />
                </ListItem>
              ) : (
                <ListItem button key={index} component={MobileNavLink} to={item.link} onClick={toggleDrawer(false)}>
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: theme.palette.silverShades.light,
                      },
                    }}
                  />
                </ListItem>
              )
            )}
          </List>
          <Box sx={{ padding: theme.spacing(2), display: "flex", justifyContent: "center" }}>
            {!auth.isAuthenticated ? (
              <Button
                color="inherit"
                onClick={() => {
                  handleLoginOpen();
                  setDrawerOpen(false);
                }}
                sx={{
                  borderRadius: "20px",
                  background: "rgba(0,0,0,0.2)",
                  backdropFilter: "blur(10px)",
                  WebkitBackdropFilter: "blur(10px)",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.6)",
                  color: theme.palette.silverShades.light,
                  width: "80%",
                  padding: theme.spacing(1),
                  fontSize: "1rem",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    background: "rgba(255,255,255,0.3)",
                  },
                }}
              >
                Login
              </Button>
            ) : (
              <Button
                color="inherit"
                onClick={() => {
                  handleLogout();
                  setDrawerOpen(false);
                }}
                sx={{
                  borderRadius: "20px",
                  background: "rgba(0,0,0,0.2)",
                  backdropFilter: "blur(10px)",
                  WebkitBackdropFilter: "blur(10px)",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.6)",
                  color: theme.palette.silverShades.light,
                  width: "80%",
                  padding: theme.spacing(1),
                  fontSize: "1rem",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    background: "rgba(255,255,255,0.3)",
                  },
                }}
              >
                Logout
              </Button>
            )}
          </Box>
        </Box>
      </Drawer>
      <ServicesBarMobile open={servicesOpen} onClose={() => setServicesOpen(false)} onBack={handleServicesBack} />
      <ProductsBarMobile open={productsOpen} onClose={() => setProductsOpen(false)} onBack={handleProductsBack} />
    </>
  );
}

export default TopNavBarMobile;
