import React, { useRef } from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

// A custom TextField using neumorphic styles
const NeumorphicTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#e0e0e0",
  borderRadius: "8px",
  boxShadow: "inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ccc",
    },
    "&:hover fieldset": {
      borderColor: "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#aaa",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#333",
    fontSize: "1.4rem",
  },
  "& .MuiInputBase-input": {
    color: "#333",
    fontSize: "1.4rem",
  },
}));

// Function to parse address components from a Google Place result
const parseAddressComponents = (place) => {
  const addressComponents = place.address_components;
  const components = {
    streetNumber: "",
    route: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
  };

  addressComponents.forEach((component) => {
    const types = component.types;
    if (types.includes("street_number")) {
      components.streetNumber = component.long_name;
    }
    if (types.includes("route")) {
      components.route = component.long_name;
    }
    if (types.includes("locality")) {
      components.city = component.long_name;
    }
    if (types.includes("administrative_area_level_1")) {
      components.province = component.long_name;
    }
    if (types.includes("postal_code")) {
      components.postalCode = component.long_name;
    }
    if (types.includes("country")) {
      components.country = component.long_name;
    }
  });

  return {
    streetAddress: `${components.streetNumber} ${components.route}`.trim(),
    city: components.city,
    province: components.province,
    postalCode: components.postalCode,
    country: components.country,
  };
};

// The revamped Addresses component using Google Maps autocomplete
const AddressSearch = ({
  currentAddressIndex,
  formData,
  setFormData,
  errors,
  handleChange,
}) => {
  const autocompleteRef = useRef(null);

  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
      <LoadScript googleMapsApiKey="AIzaSyCnFHgXLDRM4h8SFqCYHeHXjvshStr7IKg" libraries={["places"]}>
        <Autocomplete
          onLoad={(autocomplete) => {
            autocompleteRef.current = autocomplete;
          }}
          onPlaceChanged={() => {
            const place = autocompleteRef.current.getPlace();
            const address = parseAddressComponents(place);
            setFormData((prev) => {
              const updatedAddresses = [...prev.addresses];
              updatedAddresses[currentAddressIndex] = {
                ...updatedAddresses[currentAddressIndex],
                // Update the address fields based on the autocomplete result
                complex_address1: address.streetAddress,
                complex_address2: address.city,
                complex_postalcode: address.postalCode,
                complex_province: address.province,
              };
              return {
                ...prev,
                addresses: updatedAddresses,
              };
            });
          }}
        >
          <NeumorphicTextField
            label="Google Maps Address"
            name="complex_address1"
            variant="outlined"
            fullWidth
            margin="dense"
            value={formData.addresses[currentAddressIndex]?.complex_address1 || ""}
            onChange={(e) => handleChange(e, "addresses", "complex_address1")}
            error={!!errors.addresses?.[currentAddressIndex]?.complex_address1}
            helperText={errors.addresses?.[currentAddressIndex]?.complex_address1}
            autoComplete="new-google-address"
            spellCheck="false"
            autoCorrect="off"
          />
        </Autocomplete>
      </LoadScript>
      <NeumorphicTextField
        autoComplete="new-password"
        label="Complex Name"
        name="complex_name"
        variant="outlined"
        required
        fullWidth
        margin="dense"
        value={formData.addresses[currentAddressIndex]?.complex_name || ""}
        onChange={(e) => handleChange(e, "addresses", "complex_name")}
        error={!!errors.addresses?.[currentAddressIndex]?.complex_name}
        helperText={errors.addresses?.[currentAddressIndex]?.complex_name}
      />
    </Box>
  );
};

export default AddressSearch;
