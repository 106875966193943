import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomButton from "../../../../components/fields/CustomButton";
import ScrollProgressBar from "../../../../components/custom/ScrollProgressBar";
import CustomPageTitle from "../../../../components/custom/CustomTitle";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { Helmet } from "react-helmet";
const StyledSlide = styled(Box)(({ theme }) => ({
  position: "relative",
  maxwidth: "100%",
  backdropFilter: "blur(6px)",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  color: theme.palette.silverShades.light,
  textAlign: "left",
  padding: theme.spacing(2),
  borderTop: `1px inset ${theme.palette.silverShades.light}`,
  marginBottom: "25px",
  overflow: "hidden",
  marginRight:"25px"
}));

const popups = [
  {
    title: "Are you a Submetering Company?",
    content: "Submetering companies: Elevate your utility management with Meter Minds’ proprietary software. Seamlessly integrated with real-time data, it simplifies billing and enhances tenant engagement—so you can focus on growing your business.",
    image: require("../../../../assets/submetering/submetering.jpg"),
    animation: require("../../../../assets/animations/vendingSolution/vendingSolution1.json"),
  },
  {
    title: "Need More Robust Reporting?",
    content: "Need more robust reporting that your current provider is unable to give you? We provide tailor-made reporting at no additional costs (T's and C's apply).",
    image: require("../../../../assets/submetering/reporting.jpg"),
    animation: require("../../../../assets/animations/vendingSolution/vendingSolution2.json"),
  },
  {
    title: "Bring Your Meters to Our Platform",
    content: "For more information on how we can assist you in bringing all your existing and future meters on your own SGC over to our platform, contact one of our dedicated specialists today.",
    image: require("../../../../assets/submetering/platform.jpg"),
    animation: require("../../../../assets/animations/vendingSolution/vendingSolution3.json"),
  },
];

function VendingSolutionMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
  // const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();

  useEffect(() => {
    const currentSentinel = sentinelRef.current; // Capture the current ref

    if (!currentSentinel) return; // Exit if ref is not assigned

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );

    observer.observe(currentSentinel); // Use captured ref

    return () => {
      observer.unobserve(currentSentinel); // Use captured ref
      observer.disconnect(); // Clean up the observer
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(40, 40, 40, 1) 35%, rgba(60, 60, 60, 1) 100%)",
        color: theme.palette.silverShades.light,
        width: "100vw", // ensure full viewport width
        overflowX: "hidden", // prevent horizontal cutoff
        minHeight: "100vh",
        padding: theme.spacing(2),
      }}
    >
      <Helmet>
        <title>Vending Solutions - Meterminds</title>
        <meta name="description" content="Discover Meter Minds' proprietary vending software solution for effective utility management and tenant engagement." />
        <meta name="keywords" content="vending solutions, submetering software, utility management, tenant engagement, billing and reporting" />
      </Helmet>

      <Box sx={{ mt: theme.spacing(8), textAlign: "center", px: theme.spacing(2) }}>
        <Typography variant="h4" sx={{ fontSize: "1.6rem", fontWeight: "bold", color: "#fff", mb: 2 }}>
          Vending Solutions
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed", // Changed to 'fixed'
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        <IconButton
          onClick={() => navigate("/")}
          sx={{
            position: "absolute",
            left: "-5px",
            top: "-90px",
            color: theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        {/* Scroll Progress Bar */}

        {/* "Get in Touch" Button */}
        <Box
          sx={{
            position: "absolute",
            bottom: "-75vh",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {popups.map((popup, index) => (
        <StyledSlide
          key={index}
          tabIndex={0}
          onClick={() => navigate(popup.link)}
          onKeyDown={(e) => {
            if (e.key === "Enter") navigate(popup.link);
          }}
        >
          <Box sx={{ width: "100%" }}>
            {/* Left Column */}

            <Typography
              variant="h5"
              sx={{
                color: theme.palette.bronzeShades.bronze1,
                fontWeight: "bold",
                textShadow: "1px 1px 2px black",
                mb: 2,
                px: theme.spacing(2),
                fontSize: "1.6rem",
              }}
            >
              {popup.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.silverShades.light,
                textShadow: "1px 1px 2px black",
                px: theme.spacing(2),
                fontSize: "1.2rem",
              }}
            >
              {popup.content}
            </Typography>
          </Box>
        </StyledSlide>
      ))}
    </Box>
  );
}

export default VendingSolutionMobile;
