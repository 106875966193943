import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography, TextField } from "@mui/material";
import LoaderButton from "../../../components/buttons/LoaderButton";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";
import { editManagementFee, deleteManagementFee } from "../../../redux/slices/tenantManagementSlice";

const EditManagementFee = ({ tenant_uuid, management_fee, onBack, heading }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    is_landlord_fee: false,
    is_tenant_fee: false,
    fee_amount: "",
    customer_management_fee_verified: false,
    customer_management_fee_verified_message: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (management_fee) {
      setFormData({
        is_landlord_fee: management_fee.is_landlord_fee || false,
        is_tenant_fee: management_fee.is_tenant_fee || false,
        fee_amount: management_fee.fee_amount || "",
        customer_management_fee_verified: management_fee.customer_management_fee_verified || false,
        customer_management_fee_verified_message: management_fee.customer_management_fee_verified_message || "",
      });
    }
  }, [management_fee]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const handleValidation = () => {
    const newErrors = {};

    if (!formData.fee_amount) {
      newErrors.fee_amount = "Fee amount is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) return;

    try {
      await dispatch(editManagementFee({ tenant_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating management fee:", error);
    } finally {
      onBack();
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteManagementFee({ tenant_uuid })).unwrap();
      onBack();
    } catch (error) {
      console.error("Error deleting management fee:", error);
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            {heading}
          </Button>
          <Typography variant="h3">Edit Management Fee</Typography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnInset xs={6}>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Is Landlord Fee</InputLabel>
            <Select name="is_landlord_fee" value={formData.is_landlord_fee} onChange={handleChange}>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Is Tenant Fee</InputLabel>
            <Select name="is_tenant_fee" value={formData.is_tenant_fee} onChange={handleChange}>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>

          <TextField label="Fee Amount" name="fee_amount" type="number" value={formData.fee_amount} onChange={handleChange} error={!!errors.fee_amount} helperText={errors.fee_amount} fullWidth sx={{ marginBottom: 2 }} />

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Customer Fee Verified</InputLabel>
            <Select name="customer_management_fee_verified" value={formData.customer_management_fee_verified} onChange={handleChange}>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>

          <TextField label="Verification Message" name="customer_management_fee_verified_message" value={formData.customer_management_fee_verified_message} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />

          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <LoaderButton buttonText="Delete" onClick={handleDelete} color="error" />
            <LoaderButton buttonText="Cancel" onClick={onBack} />
          </Box>
        </ColumnInset>
      </Row>
    </Container>
  );
};

export default EditManagementFee;
