import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import CardTypography from "../Typography/CardTypography";

import Chip from "../misc/Chip";

const CustomerManagementFee = ({ tenant_uuid,management_fee, callback_editManagementFee }) => {
  console.log({management_fee})
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
    maxWidth: "400px",
  };

  const statusIcon = (status) => (status ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />);

  const click_editfee = ({ management_uuid }) => {
    callback_editManagementFee({ management_uuid,tenant_uuid });
  };

  return (
    <Box sx={neumorphicStyles}>
      <CardTypography variant="h4" sx={{ marginBottom: 2 }}>
        Customer Management Fee
      </CardTypography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
        {statusIcon(management_fee.is_landlord_fee)}
        <CardTypography >Landlord Fee</CardTypography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
        {statusIcon(management_fee.is_tenant_fee)}
        <CardTypography >Tenant Fee</CardTypography>
      </Box>

      <Box sx={{ marginBottom: 1 }}>
        <CardTypography>
          <strong>Fee Amount:</strong> {management_fee.fee_amount || null}
        </CardTypography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        {management_fee.is_landlord_fee || management_fee.is_tenant_fee? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
        <Chip label={management_fee.customer_management_fee_verified ? "Verified" : "Unverified"} verified={management_fee.customer_management_fee_verified} />
      </Box>

<Box sx={{ marginBottom: 1 ,marginTop:1,paddingLeft:6}}>
    <CardTypography> {management_fee.customer_management_fee_verified_message || null}</CardTypography>
    </Box>
  
  <Box sx={{ marginBottom: 1 ,marginTop:1,paddingLeft:6}}>
    <CardTypography>Date Created: {management_fee.created_date?new Date(management_fee.created_date).toLocaleDateString():null}</CardTypography>
    </Box>

      <Button
        variant="contained"
        onClick={() => click_editfee({ management_uuid: management_fee.management_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
      >
        Edit
      </Button>
    </Box>
  );
};

export default CustomerManagementFee;
