// Section3.jsx
import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";

const SectionWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "70vh", 
  padding: theme.spacing(4),
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "transparent",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  color: "#fff",
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.8rem",
  },
}));

// Use a grid layout on PC to display 2 cards per row; mobile remains a single column.
const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "800px",
  display: "grid",
  gap: theme.spacing(2),
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

const Card = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.15)",
  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)",
  borderRadius: "15px",
  padding: theme.spacing(2),
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
  textAlign: "center",
  color: "#fff",
  border: "1px solid rgba(255,255,255,0.2)",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: "3rem",
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
    marginBottom: theme.spacing(0.5),
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const CardText = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const Section3 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Mobile: show 3 short cards.
  const mobileCards = [
    {
      icon: (
        <HistoryOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.bronzeShades.bronze1 }}
        />
      ),
      text: "With over 25 years of experience in the metering industry",
    },
    {
      icon: (
        <EmojiObjectsOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.bronzeShades.bronze1 }}
        />
      ),
      text: "Innovative solutions tailored to your needs",
    },
    {
      icon: (
        <AccessTimeOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.bronzeShades.bronze1 }}
        />
      ),
      text: "24hr turnaround on custom reports for our landlords",
    },
  ];

  // PC: show 4 detailed cards.
  const pcCards = [
    {
      icon: (
        <HistoryOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.bronzeShades.bronze1 }}
        />
      ),
      title: "Experience & Expertise",
      text:
        "We are a sub-metering company offering electricity, water, and gas meters. With over 25 years in the industry, our expertise sets us apart.",
    },
    {
      icon: (
        <EmojiObjectsOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.bronzeShades.bronze1 }}
        />
      ),
      title: "Outstanding Customer Service",
      text:
        "Our excellent customer service ensures prompt support so you get your systems running quickly.",
    },
    {
      icon: (
        <AccessTimeOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.bronzeShades.bronze1 }}
        />
      ),
      title: "Competitive Pricing",
      text:
        "We offer cost-effective solutions that are more affordable than our competitors without compromising on quality.",
    },
    {
      icon: (
        <TrendingUpOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.bronzeShades.bronze1 }}
        />
      ),
      title: "Custom Reporting",
      text:
        "Our dev team delivers a 24hr turnaround on custom reports for both landlords and customers.",
    },
  ];

  const displayCards = isMobile ? mobileCards : pcCards;

  return (
    <SectionWrapper>
      <CardsContainer>
        {displayCards.map((card, index) => (
          <Card key={index}>
            <IconWrapper>{card.icon}</IconWrapper>
            {!isMobile && card.title && <CardTitle variant="h6">{card.title}</CardTitle>}
            <CardText>{card.text}</CardText>
          </Card>
        ))}
      </CardsContainer>
    </SectionWrapper>
  );
};

export default Section3;
