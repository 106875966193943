import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

// import backgroundImage from "../../../assets/electricity/hero3.jpg";
import landisLogo from "../../../assets/suppliers/landis.jpg";
import hexingLogo from "../../../assets/suppliers/hexing.jpg";
import conlogLogo from "../../../assets/suppliers/conlog.png";
import laisonLogo from "../../../assets/suppliers/laison.png";

const NeumorphicCard = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.85)",
  color: theme.palette.silverShades.light,
  boxShadow: `2px 2px 2px ${theme.palette.blackShades.medium}, -2px -2px 2px ${theme.palette.blackShades.light}`,
  borderRadius: "15px",
  maxWidth: "92%", // use full viewport width
  height: "30vh", // make cards shorter (70% of viewport height)
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start", // items start at the top
  overflowY: "auto",
  boxSizing: "border-box",
  "&:hover": {
    boxShadow: `5px 5px 15px ${theme.palette.blackShades.medium}, -5px -5px 15px ${theme.palette.blackShades.light}`,
    transform: "translateY(-5px)",
  },
}));

const SupplierLogo = styled("img")(({ theme }) => ({
  width: "150px", // reduced logo size for mobile
  height: "auto",
  objectFit: "cover",
  marginBottom: theme.spacing(1),
}));

function SuppliersMobile() {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCardClick = (supplierName) => {
    navigate(`/supplier/${supplierName}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(40,40,40,1) 35%, rgba(60,60,60,1) 100%)",
        color: theme.palette.silverShades.light,
        width: "100vw",
        overflowY: "auto",
        minHeight: "100vh",
        p: theme.spacing(2),
        mb: "25px",
      }}
    >
      <Box sx={{ mt: theme.spacing(4), textAlign: "center", pr: theme.spacing(4), mt: 8 }}>
        <Typography variant="h4" sx={{ fontSize: "1.6rem" }}>
          Our Trusted SuppliersMobile
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1rem", mt: 1 }}>
          We partner with top-tier suppliers to ensure the highest quality and reliability for all our products. Our extensive network allows us to deliver exceptional value and performance to our customers.
        </Typography>
      </Box>

      <Box sx={{ mt: theme.spacing(4), display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
        {/* Supplier 1: Landis */}
        <NeumorphicCard
          onClick={() => handleCardClick("landis-mobile")}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
            boxSizing: "border-box",
            p: 3,
          }}
        >
          <CardContent sx={{ textAlign: "center", p: 3 }}>
            <SupplierLogo src={landisLogo} alt="Landis Logo" sx={{ width: "80%", mb: 2, mx: "auto" }} />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark, fontSize: "1rem" }}>
              We integrate Landis' high-quality meters into our custom-built smart metering systems, ensuring accurate data collection and reliable performance tailored to your needs.
            </Typography>
          </CardContent>
        </NeumorphicCard>

        {/* Supplier 2: Hexing */}
        <NeumorphicCard
          onClick={() => handleCardClick("hexing-mobile")}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
            boxSizing: "border-box",
            p: 3,
          }}
        >
          <CardContent sx={{ textAlign: "center", p: 3 }}>
            <SupplierLogo src={hexingLogo} alt="Hexing Logo" sx={{ width: "80%", mb: 2, mx: "auto" }} />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark, fontSize: "1rem" }}>
              We utilize Hexing's advanced meters in our custom-designed energy management solutions, providing seamless integration and optimal energy monitoring for your applications.
            </Typography>
          </CardContent>
        </NeumorphicCard>

        {/* Supplier 2: laison */}
        <NeumorphicCard
          onClick={() => handleCardClick("laison-mobile")}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
            boxSizing: "border-box",
            p: 3,
          }}
        >
          <CardContent sx={{ textAlign: "center", p: 3 }}>
            <SupplierLogo src={laisonLogo} alt="Laison Logo" sx={{ width: "80%", mb: 2, mx: "auto" }} />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark, fontSize: "1rem" }}>
              We incorporate Laison's innovative metering technologies into our platform, delivering cost-effective, high-precision data collection and seamless utility management for diverse applications{" "}
            </Typography>
          </CardContent>
        </NeumorphicCard>

        {/* Supplier 2: Hexing */}
        <NeumorphicCard
          onClick={() => handleCardClick("Conlog-mobile")}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
            boxSizing: "border-box",
            p: 3,
          }}
        >
          <CardContent sx={{ textAlign: "center", p: 3 }}>
            <SupplierLogo src={conlogLogo} alt="Conlog Logo" sx={{ width: "80%", mb: 2, mx: "auto" }} />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark, fontSize: "1rem" }}>
              We integrate Conlog's robust prepaid and postpaid meters into our advanced energy management solutions, ensuring secure billing processes and enhanced consumer engagement{" "}
            </Typography>
          </CardContent>
        </NeumorphicCard>
      </Box>
    </Box>
  );
}

export default SuppliersMobile;
