// src/Components/LandisMobile.jsx
import React from "react";
import { Box, Typography, CardContent, Button, IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import placeholderImage from "../../../assets/suppliers/landis.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";




const NeumorphicCard = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.85)",
  color: theme.palette.silverShades.light,
  boxShadow: `2px 2px 2px ${theme.palette.blackShades.medium}, -2px -2px 2px ${theme.palette.blackShades.light}`,
  borderRadius: "15px",
  width: "250px",
  padding: theme.spacing(2),
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    boxShadow: `5px 5px 15px ${theme.palette.blackShades.medium}, -5px -5px 15px ${theme.palette.blackShades.light}`,
    transform: "translateY(-5px)",
  },
}));

const MeterImage = styled("img")(({ theme }) => ({
  width: "150px",
  height: "auto",
  marginBottom: theme.spacing(2),
}));

function LandisMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  // All LandisMobile+Gyr meters (excluding the commented-out item)
  const meters = [
    {
      uuid: "1ffAX2",
      code: "GEMLIGHT",
      description: "Single Phase Integrated meter from Landis+Gyr.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "22Os2Q",
      code: "FTY",
      description: "Single Phase Split meter from Landis+Gyr.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "41ZAPN",
      code: "E460",
      description: "Single Phase Smart meter from Landis+Gyr.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "5J8G6M",
      code: "E650",
      description: "3-Phase Smart meter from Landis+Gyr.",
      dataSheet: "#",
      image: placeholderImage,
    },
  ];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "rgb(0,0,0)",
        backgroundImage:
          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(20,20,20,1) 35%, rgba(41,41,41,1) 100%)",
        p: 4,
      }}
    >

<IconButton
        onClick={() => navigate("/products/suppliers")}
        sx={{
          position: "absolute",
          left: "20px",
          top: "50px",
          color: theme.palette.bronzeShades.bronze1,
          backgroundColor: "transparent",
          "&:hover": {
            color: theme.palette.bronzeShades.bronze3,
          },
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton>

      <Box sx={{ mt: theme.spacing(4),mb:theme.spacing(4), textAlign: "center", pr: theme.spacing(4), mt: 8 }}>
        <Typography variant="h4" color="#fff" sx={{ fontSize: "1.6rem" }}>
        Landis+Gyr Meters
        </Typography>
        <Typography variant="h6" color="#fff" sx={{ fontSize: "1rem", mt: 1 }}>
        Discover our Landis+Gyr electricity meters for advanced and reliable power measurement. </Typography>
      </Box>


      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
        {meters.map((meter) => (
          <NeumorphicCard key={meter.uuid}>
            <CardContent sx={{ textAlign: "center" }}>
              <MeterImage src={meter.image} alt={meter.code} />
              <Typography variant="h6" sx={{ mb: 1 }}>
                {meter.code}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.silverShades.dark, mb: 2 }}
              >
                {meter.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href={meter.dataSheet}
                target="_blank"
                rel="noopener"
              >
                Download Data Sheet
              </Button>
            </CardContent>
          </NeumorphicCard>
        ))}
      </Box>
    </Box>
  );
}

export default LandisMobile;
