import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import CustomPageTitle from "../../../../components/custom/CustomTitle";
import CustomButton from "../../../../components/fields/CustomButton";
import ScrollProgressBar from "../../../../components/custom/ScrollProgressBar";

import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SecurityIcon from "@mui/icons-material/Security";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HouseIcon from "@mui/icons-material/House";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
// import InsightsSharpIcon from "@mui/icons-material/InsightsSharp";
// import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { Helmet } from "react-helmet";

const StyledSlide = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: "100%",
  backdropFilter: "blur(6px)",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  color: theme.palette.silverShades.light,
  textAlign: "left",
  padding: theme.spacing(4),
  borderTop: `1px inset ${theme.palette.silverShades.light}`,
  marginBottom: "25px",
  overflow: "hidden",
  marginRight: theme.spacing(2),
}));

const StyledCardBox = styled(Box)(({ theme, bgColor }) => ({
  position: "absolute",
  top: "-15px",
  left: "-15px",
  height: "80px",
  width: "80px",
  borderRadius: "50%",
  backgroundColor: bgColor || theme.palette.blackShades.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: `0 4px 6px rgba(0,0,0,0.1)`,
  zIndex: 1,
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "60px",
    top: "-15px",
    right: "-15px",
  },
}));

const CardIcon = styled(({ icon: IconComponent, ...other }) => <IconComponent {...other} />)(({ theme }) => ({
  color: theme.palette.silverShades.dark,
  fontSize: "36px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

const popups = [
  {
    title: "Accurate Billing",
    content: "Submetering provides precise measurements of individual unit consumption, ensuring residents are billed accurately for their actual usage rather than relying on averaged estimates.",
  },
  {
    title: "Cost Savings",
    content: "By tracking consumption closely, complexes can identify inefficiencies and reduce overall utility costs. This can lead to lower monthly bills for residents.",
  },
  {
    title: "Encourages Conservation",
    content: "When residents see their individual usage, they are more likely to conserve resources, leading to overall reduced consumption and environmental impact.",
  },
  {
    title: "Simplified Management",
    content: "Automated data collection and reporting reduce administrative burdens for property managers, streamlining billing processes and minimizing disputes.",
  },
  {
    title: "Dispute Resolution",
    content: "Clear, transparent data makes it easier to resolve billing disputes quickly and fairly, enhancing resident satisfaction.",
  },
  {
    title: "Increased Property Value",
    content: "Properties with efficient utility management and transparent billing practices can attract more tenants and potentially increase property values.",
  },
  {
    title: "Transparency",
    content: "Meter Minds realizes that no two complexes are the same. Our adaptability allows us to customize not only our solution offering to you but also provide you with tailor-made reports based on your requirements.",
  },
];

const getCardDetails = (index, theme) => {
  switch (index % 7) {
    case 0:
      return { icon: PrecisionManufacturingIcon, bgColor: theme.palette.blackShades.medium };
    case 1:
      return { icon: AttachMoneyIcon, bgColor: theme.palette.blackShades.medium };
    case 2:
      return { icon: SecurityIcon, bgColor: theme.palette.blackShades.medium };
    case 3:
      return { icon: ManageAccountsIcon, bgColor: theme.palette.blackShades.medium };
    case 4:
      return { icon: AccountBalanceWalletIcon, bgColor: theme.palette.blackShades.medium };
    case 5:
      return { icon: HouseIcon, bgColor: theme.palette.blackShades.medium };
    case 6:
      return { icon: VisibilityIcon, bgColor: theme.palette.blackShades.medium };
    default:
      return { icon: DesignServicesIcon, bgColor: theme.palette.blackShades.medium };
  }
};

function ComplexesMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
  const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();

  useEffect(() => {
    const currentSentinel = sentinelRef.current;

    if (!currentSentinel) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );

    observer.observe(currentSentinel);

    return () => {
      observer.unobserve(currentSentinel);
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100vw", // ensure full viewport width
        overflowX: "hidden", // prevent horizontal cutoff
        minHeight: "100vh",
        padding: theme.spacing(2), // use uniform padding instead of theme.spacing(1,4)
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(40,40,40,1) 35%, rgba(60,60,60,1) 100%)",
        color: theme.palette.silverShades.light,
      }}
    >
      <Helmet>
        <title>Complexes - Meterminds</title>
        <meta name="description" content="Comprehensive metering solutions for residential and commercial complexes." />
        <meta name="keywords" content="complex metering, residential utilities, commercial metering, complex management" />
      </Helmet>
      {/* <div ref={sentinelRef} /> */}

      {/* <CustomPageTitle
        icon={PrecisionManufacturingIcon}
        tooltip="Benefits for Complexes"
        title="Benefits for Complexes"
        sx={{
          textAlign: "center",
        }}
      /> */}
      <Box sx={{ mt: theme.spacing(10), textAlign: "center", px: theme.spacing(2) }}>
        <Typography
          variant="h4" sx={{ fontSize: "1.6rem", fontWeight: "bold", color: "#fff", mb: 2 }}
        >
          Benefits for Complexes
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={() => navigate("/services/utility-sub-metering")}
          sx={{
            position: "absolute",
            left: "-5px",
            top: "-100px",
            color: isScrolled ? theme.palette.silverShades.dark : theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: isScrolled ? theme.palette.bronzeShades.bronze3 : theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        {/* Scroll Progress Bar */}
        {/* <ScrollProgressBar contentRef={contentRef} /> */}

        {/* "Get in Touch" Button */}
        <Box
          sx={{
            position: "absolute",
            bottom: "-75vh",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {popups.map((popup, index) => {
        const { icon: IconComponent, bgColor } = getCardDetails(index, theme);

        return (
          <StyledSlide key={index}>
            <StyledCardBox bgColor={bgColor} aria-hidden="true">
              <CardIcon icon={IconComponent} />
            </StyledCardBox>

            <Box sx={{ width: "100%" }}>
              <Typography
                 variant="h5"
                 sx={{
                   color: theme.palette.bronzeShades.bronze1,
                   fontWeight: "bold",
                   textShadow: "1px 1px 2px black",
                   mb: 2,
                   px: theme.spacing(2),
                   fontSize: "1.6rem",
                 }}
              >
                {popup.title}
              </Typography>

              <Typography
              variant="body1"
              sx={{
                color: theme.palette.silverShades.light,
                textShadow: "1px 1px 2px black",
                px: theme.spacing(2),
                fontSize: "1.2rem",
              }}
              >
                {popup.content}
              </Typography>
            </Box>
          </StyledSlide>
        );
      })}
    </Box>
  );
}

export default ComplexesMobile;
