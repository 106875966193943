import React from "react";
import { Box, Typography, Divider, Card, CardContent, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import hero1 from "../../../../assets/electricity/hero1.jpg";
import hero2 from "../../../../assets/bulbs/bulbs3.jpg";
import hero3 from "../../../../assets/electricity/hero3.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const mobileCardData = [
  {
    title: "Property Owners",
    image: hero1,
    link: "/services/property-owners-mobile",
    body: "Simplify utility management with remote access and monthly reports.",
  },
  {
    title: "For Complexes",
    image: hero2,
    link: "/services/complexes-mobile",
    body: "Improve billing accuracy, reduce disputes, and boost resident satisfaction.",
  },
  {
    title: "Managing Agents/Developers",
    image: hero3,
    link: "/services/developers-mobile",
    body: "Enhance revenue management with precise billing and automated data.",
  },
];

// Neumorphic shadow for a soft, raised look.
const neumorphicShadow = (theme) => `
  2px 2px 4px ${alpha(theme.palette.grey[700], 0.6)},
  -2px -2px 4px ${alpha(theme.palette.grey[300], 0.6)}
`;

// Mobile Styled Card (vertical layout)
const MobileStyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderRadius: "12px",
  background: `linear-gradient(145deg, rgba(255,255,255,0.9), rgba(245,245,245,0.9))`,
  boxShadow: neumorphicShadow(theme),
  border: `1px solid ${theme.palette.grey[400]}`,
  marginBottom: theme.spacing(3),
  cursor: "pointer",
}));

// Mobile Card Image
const MobileCardImage = styled("img")({
  width: "100%",
  height: "180px",
  objectFit: "cover",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
});

// Mobile Card Content
const MobileCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function UtilitySubMeteringMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (!isMobile) return null;

  return (
    <Box
      sx={{
        marginTop: "50px",
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#f0f0f0",
        padding: theme.spacing(2),
        boxSizing: "border-box",
        overflowY: "auto",
      }}
    >
      <Helmet>
        <title>Utility Sub-Metering - Meterminds</title>
        <meta name="description" content="Learn about our advanced utility sub-metering solutions for landlords and developers." />
      </Helmet>
      {/* Manually render the title */}
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <Typography variant="h4" sx={{ fontSize: "1.6rem", color: "#333", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <BoltOutlinedIcon sx={{ mr: 1, fontSize: "2.5rem", color: "#333" }} />
          Utility Sub-Metering
        </Typography>
      </Box>

      <IconButton
        onClick={() => navigate("/")}
        sx={{
          position: "absolute",
          left: "-5px",
          top: "58px",
          color: "#333",
          backgroundColor: "transparent",
          "&:hover": {
            color: theme.palette.bronzeShades.bronze5,
          },
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton>

      <Box
        sx={{
          mt: theme.spacing(2),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(3),
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        {mobileCardData.map((card, index) => (
          <MobileStyledCard key={index} onClick={() => navigate(card.link)}>
            <MobileCardImage src={card.image} alt={card.title} />
            <MobileCardContent>
              <Typography variant="h5" color={theme.palette.primary.main} align="center" sx={{ fontSize: "2rem" }}>
                {card.title}
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body1" align="center" sx={{ fontSize: "1.2rem" }}>
                {card.body}
              </Typography>
            </MobileCardContent>
          </MobileStyledCard>
        ))}
      </Box>
    </Box>
  );
}

export default UtilitySubMeteringMobile;
