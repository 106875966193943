// ProductsBarMobile.jsx
import React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText, IconButton, Drawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const ProductsBarMobile = ({ open, onClose,onBack  }) => {
  const theme = useTheme();
  const productsItems = [
    { label: "Suppliers", link: "/products/suppliers", icon: <Inventory2OutlinedIcon sx={{ color: theme.palette.bronzeShades.bronze1 }} /> },
  //   { label: "Connectivity", link: "/products/connectivity", icon: <ConnectWithoutContactOutlinedIcon sx={{ color: theme.palette.bronzeShades.bronze1 }} /> },
  ];
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          background: "rgba(255,255,255,0.1)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          border: "1px solid rgba(255,255,255,0.3)",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
        },
      }}
    >
      <Box sx={{ width: 250, padding: theme.spacing(2), display: "flex", flexDirection: "column", height: "100%" }}>
        <List sx={{ flexGrow: 1 }}>
          {productsItems.map((item, index) => (
            <ListItem button key={index} component={RouterLink} to={item.link} onClick={onClose}>
              <ListItemIcon sx={{ minWidth: "40px" }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ sx: { fontSize: "1.5rem", fontWeight: "bold", color: theme.palette.silverShades.light } }}
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: "flex", justifyContent: "center", padding: theme.spacing(1) }}>
  <IconButton onClick={onBack}>
    <ArrowBackIosNewIcon sx={{ color: theme.palette.silverShades.light, fontSize: "2rem" }} />
  </IconButton>
</Box>
      </Box>
    </Drawer>
  );
};

export default ProductsBarMobile;
