// Update Section2.jsx (Section2) to conditionally render the slider on mobile and a single row on larger screens:

import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { keyframes } from "@mui/system";

const SectionWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxSizing: "border-box",
  minHeight: "70vh", 
}));

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const CardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  overflow: "hidden",
  marginTop: theme.spacing(4),
  justifyContent: "flex-start",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 2),
  },
}));

const CardsSlider = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  animation: `${scroll} 10s linear infinite`,
}));

const Card = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)",
  padding: theme.spacing(3),
  borderRadius: "15px",
  boxShadow: "8px 8px 16px rgba(0, 0, 0, 0.3), -8px -8px 16px rgba(255, 255, 255, 0.1)",
  textAlign: "center",
  maxWidth: "300px",
  width: "100%",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5),
    minWidth: "150px",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

const Section2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cards = [
    {
      Icon: SavingsOutlinedIcon,
      title: "Cost Effective",
      description:
        "Our company provides high-quality, affordable services with competitive and transparent pricing. By optimizing processes and using innovative solutions, we ensure excellent value without compromising quality, allowing you to focus on what matters most.",
    },
    {
      Icon: GroupsOutlinedIcon,
      title: "Embracing Innovations",
      description:
        "With our modern and flexible approach to continuous improvement, our property software offers the adaptability that you need to ensure optimum utility recovery.",
    },
    {
      Icon: TipsAndUpdatesOutlinedIcon,
      title: "Tailored Solutions",
      description:
        "We provide fully customized reports tailored to your specific needs at no extra cost, with a quick turnaround to ensure you get the insights you need on time. Our flexible approach allows us to create custom packages, including pricing, designed to suit your requirements perfectly.",
    },
  ];

  return (
    <SectionWrapper>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
        }}
      >
        Expert Utility Management for your Sub-Metering needs
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mt: 2,
          textAlign: "center",
          maxWidth: 800,
          fontSize: { xs: "0.9rem", sm: "1rem", md: "1.125rem" },
        }}
      >
        We provide precise monitoring, billing, and reporting services, ensuring efficient energy usage and cost control for property owners and managers. With our advanced technology and seamless integration, we deliver accurate, reliable data to help you optimize energy consumption and improve tenant satisfaction.
      </Typography>
      <CardsContainer>
      {isMobile ? (
  <CardsSlider>
    {cards.map((card, index) => (
      <Card key={`first-${index}`}>
        <IconWrapper>
          <card.Icon fontSize="inherit" />
        </IconWrapper>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            mb: 1,
            fontSize: { xs: "1.1rem", sm: "1.25rem", md: "1.5rem" },
          }}
        >
          {card.title}
        </Typography>
      </Card>
    ))}
    {cards.map((card, index) => (
      <Card key={`second-${index}`}>
        <IconWrapper>
          <card.Icon fontSize="inherit" />
        </IconWrapper>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            mb: 1,
            fontSize: { xs: "1.1rem", sm: "1.25rem", md: "1.5rem" },
          }}
        >
          {card.title}
        </Typography>
      </Card>
    ))}
  </CardsSlider>
) : (
  <Box
    sx={{
      display: "flex",
      gap: theme.spacing(2),
      justifyContent: "space-around",
      width: "100%",
    }}
  >
    {cards.map((card, index) => (
      <Card key={index}>
        <IconWrapper>
          <card.Icon fontSize="inherit" />
        </IconWrapper>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            mb: 1,
            fontSize: { xs: "1.1rem", sm: "1.25rem", md: "1.5rem" },
          }}
        >
          {card.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" } }}
        >
          {card.description}
        </Typography>
      </Card>
    ))}
  </Box>
)}

      </CardsContainer>
    </SectionWrapper>
  );
};

export default Section2;
