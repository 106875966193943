// ServicesBarMobile.jsx
import React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText, IconButton, Drawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const ServicesBarMobile = ({ open, onClose,onBack  }) => {
  const theme = useTheme();
  const servicesItems = [
    { label: "Utility Sub-Metering", link: "/services/utility-sub-metering", icon: <BoltOutlinedIcon sx={{ color: theme.palette.bronzeShades.bronze1 }} /> },
    { label: "Vending Solution", link: "/services/vending-solution", icon: <GpsFixedOutlinedIcon sx={{ color: theme.palette.bronzeShades.bronze1 }} /> },
    { label: "Bulk Metering", link: "/services/bulk-metering", icon: <ProductionQuantityLimitsOutlinedIcon sx={{ color: theme.palette.bronzeShades.bronze1 }} /> },
    { label: "Recharge", link: "/services/recharge", icon: <PowerSettingsNewIcon sx={{ color: theme.palette.bronzeShades.bronze1 }} /> },
  ];

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          background: "rgba(255,255,255,0.1)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          border: "1px solid rgba(255,255,255,0.3)",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
        },
      }}
    >
      <Box sx={{ width: 250, padding: theme.spacing(2), display: "flex", flexDirection: "column", height: "100%" }}>
        <List sx={{ flexGrow: 1 }}>
          {servicesItems.map((item, index) => (
            <ListItem button key={index} component={RouterLink} to={item.link} onClick={onClose}>
              <ListItemIcon sx={{ minWidth: "40px" }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ sx: { fontSize: "1.5rem", fontWeight: "bold", color: theme.palette.silverShades.light } }}
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: "flex", justifyContent: "center", padding: theme.spacing(1) }}>
  <IconButton onClick={onBack}>
    <ArrowBackIosNewIcon sx={{ color: theme.palette.silverShades.light, fontSize: "2rem" }} />
  </IconButton>
</Box>
      </Box>
    </Drawer>
  );
};

export default ServicesBarMobile;
