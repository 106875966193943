// src/pages/services/Recharge/RechargeMobile.jsx
import React, { useState } from "react";
import { Box, Typography, Grid, FormControlLabel, IconButton } from "@mui/material";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import CustomTextField from "../../../../components/fields/CustomTextField";
import CustomCheckbox from "../../../../components/fields/CustomCheckbox";

import { keyframes } from "@emotion/react";

import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import GenericEnhancedTitle from "../../../../components/custom/PageTitle";
import CustomButton from "../../../../components/fields/CustomButton";
import backgroundImageForm from "../../../../assets/images/lightbulbs1.jpg";
import Svg1 from "../../../../assets/svg/recharge1.svg";
import Svg2 from "../../../../assets/svg/recharge2.svg";
import { Helmet } from "react-helmet";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate } from "react-router-dom";


function RechargeMobile() {
  const theme = useTheme();
  const navigate=useNavigate();
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showMeterForm, setShowMeterForm] = useState(false);

  const handleRegisterCheckboxChange = (event) => {
    setShowRegisterForm(event.target.checked);
    // Uncheck the other checkbox if this one is checked
    if (event.target.checked) {
      setShowMeterForm(false);
    }
  };

  const handleMeterCheckboxChange = (event) => {
    setShowMeterForm(event.target.checked);
    // Uncheck the other checkbox if this one is checked
    if (event.target.checked) {
      setShowRegisterForm(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        minWidth: "100vw",
        overflowX: "hidden", // prevent horizontal cutoff
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgb(0, 0, 0)", // Fallback background color
        backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(41, 41, 41, 1) 100%)",
        display: "flex", // Enable Flexbox
        flexDirection: "column", // Stack children vertically
        alignItems: "center", // Center children horizontally
        justifyContent: "flex-start", // Start from the top vertically
      }}
    >
      <Helmet>
        <title>Recharge - Meterminds</title>
        <meta name="description" content="Simplify utility payments with Meter Minds' seamless recharge solutions for tenants and landlords." />
        <meta name="keywords" content="utility recharge, tenant payments, landlord utilities, seamless payment solutions" />
      </Helmet>
      {/* Enhanced Global Title */}

      <Box sx={{ mt: theme.spacing(8), textAlign: "center", px: theme.spacing(2) }}>
        <Typography variant="h4" sx={{ fontSize: "1.5rem", fontWeight: "bold", color: "#fff", mb: 2 }}>
          Recharge Your Account
        </Typography>
      </Box>

      <IconButton
          onClick={() => navigate("/")}
          sx={{
            position: "absolute",
            left: "-5px",
            top: "50px",
            color: theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

      {/* Checkboxes */}
      <Box sx={{ width: "90%", position: "relative", left: "5%", mb: "25px" }}>
        <FormControlLabel
          control={<CustomCheckbox checked={showRegisterForm} onChange={handleRegisterCheckboxChange} color="primary" />}
          label={
            <Typography variant="h5" sx={{ color: theme.palette.silverShades.light, fontSize: "1rem" }}>
              Not already one of our satisfied customers - register here
            </Typography>
          }
        />
      </Box>

      <Box sx={{ width: "90%", position: "relative", left: "5%" }}>
        <FormControlLabel
          control={<CustomCheckbox checked={showMeterForm} onChange={handleMeterCheckboxChange} color="primary" />}
          label={
            <Typography variant="h5" sx={{ color: theme.palette.silverShades.light, fontSize: "1rem" }}>
              Already a Meter Minds customer - simply enter your meter number below
            </Typography>
          }
        />
      </Box>

      {/* Registration Form */}
      {showRegisterForm && (
        <Box sx={{ width: "90%", mb: "50px", mt: "25px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField fullWidth label="First Name" variant="outlined" sx={{ fontSize: "1.2rem" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField fullWidth label="Surname" variant="outlined" sx={{ fontSize: "1.2rem" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField fullWidth label="Email" variant="outlined" sx={{ fontSize: "1.2rem" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField fullWidth label="Cell Nr" variant="outlined" sx={{ fontSize: "1.2rem" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField fullWidth label="Meter No" variant="outlined" sx={{ fontSize: "1.2rem" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField fullWidth label="Utility" variant="outlined" sx={{ fontSize: "1.2rem" }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CustomButton link="/services/recharge" text="Submit" sx={{ fontSize: "1.2rem", padding: "1rem 2rem" }} />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Meter Number Form */}
      {showMeterForm && (
        <Box sx={{ width: "90%", mb: "50px", mt: "25px" }}>
          <Grid container spacing={2}>
            {/* Meter Number */}
            <Grid item xs={12}>
              <CustomTextField fullWidth label="Meter No" variant="outlined" />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CustomButton link="/services/recharge" text="Submit" />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box
        component="img"
        src={Svg1}
        alt="SVG 1"
        sx={{
          position: "fixed",
          bottom: "40px",
          left: "20px",
          width: "100px",
          height: "100px",
          zIndex: 0,
        }}
      />
      <Box
        component="img"
        src={Svg2}
        alt="SVG 2"
        sx={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          width: "100px",
          height: "100px",
          zIndex: 0,
        }}
      />
    </Box>
  );
}

export default RechargeMobile;
