// src/components/layout/WebsiteLayout.jsx
import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import Footer from "../components/common/Footer";

import TopNavBar from "../pages/website/navigation/TopNavBar";
import TopNavBarMobile from "../pages/website/navigation/TopNavBarMobile";

function WebsiteLayout() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sentinelRef = useRef(null);
  const contentRef = useRef(null);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", overflowX: "hidden" }}>
      {isMobile ? <TopNavBarMobile /> : <TopNavBar />}
      <Box
        component="main"
        className="main-content" // Add a class name for styling
        ref={contentRef}
        sx={{
          flexGrow: 1,
          width: "100%",
          overflowX: "hidden",
          backgroundColor: theme.palette.blackShades.black,
        }}
      >
        <Outlet context={{ contentRef, sentinelRef }} />
      </Box>
      <Footer />
    </Box>
  );
}

export default WebsiteLayout;
