import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import CustomPageTitle from "../../../../components/custom/CustomTitle";
import CustomButton from "../../../../components/fields/CustomButton";
import ScrollProgressBar from "../../../../components/custom/ScrollProgressBar";

import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BuildIcon from "@mui/icons-material/Build";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SavingsIcon from "@mui/icons-material/Savings";
import NatureIcon from "@mui/icons-material/Nature";
import GavelIcon from "@mui/icons-material/Gavel";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { Helmet } from "react-helmet";

const StyledSlide = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: "100%",
  backdropFilter: "blur(6px)",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  color: theme.palette.silverShades.light,
  textAlign: "left",
  padding: theme.spacing(4),
  borderTop: `1px inset ${theme.palette.silverShades.light}`,
  marginBottom: "25px",
  overflow: "hidden",
  marginRight: theme.spacing(2),
}));

const StyledCardBox = styled(Box)(({ theme, bgColor }) => ({
  position: "absolute",
  top: "-15px",
  left: "-15px",
  height: "80px",
  width: "80px",
  borderRadius: "50%",
  backgroundColor: bgColor || theme.palette.blackShades.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: `0 4px 6px rgba(0,0,0,0.1)`,
  zIndex: 1,
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "60px",
    top: "-15px",
    right: "-15px",
  },
}));

const CardIcon = styled(({ icon: IconComponent, ...other }) => <IconComponent {...other} />)(({ theme }) => ({
  color: theme.palette.silverShades.dark,
  fontSize: "36px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

const popups = [
  {
    title: "Enhanced Revenue Control",
    content: "Submetering allows for accurate billing based on individual unit consumption, ensuring that property managers receive appropriate compensation for utilities.",
  },
  {
    title: "Reduced Disputes",
    content: "Clear, detailed usage data minimizes billing disputes with tenants, streamlining communication and reducing administrative burdens.",
  },
  {
    title: "Operational Efficiency",
    content: "Automated data collection and reporting simplify management processes, allowing property managers to focus on other critical tasks.",
  },
  {
    title: "Attractive Amenities",
    content: "For developers, incorporating submetering can enhance the appeal of a property, attracting tenants interested in transparency and efficiency.",
  },
  {
    title: "Cost Savings",
    content: "By identifying and addressing inefficiencies, property managers can lower overall utility costs, improving the property’s bottom line.",
  },
  {
    title: "Sustainability Initiatives",
    content: "Submetering supports sustainability goals by encouraging responsible resource usage among tenants, which can enhance the property's reputation.",
  },
  {
    title: "Compliance and Reporting",
    content: "Submetering assists in meeting regulatory requirements and simplifies reporting, making it easier to maintain compliance.",
  },
  {
    title: "Increased Tenant Satisfaction",
    content: "Transparent billing and accountability can lead to higher tenant satisfaction and retention rates.",
  },
  {
    title: "Long-Term Value",
    content: "For developers, properties equipped with advanced utility management features, like submetering, can command higher resale values and attract investment.",
  },
];

const getCardDetails = (index, theme) => {
  switch (index % 9) {
    case 0:
      return { icon: MonetizationOnIcon, bgColor: theme.palette.blackShades.medium };
    case 1:
      return { icon: HandshakeIcon, bgColor: theme.palette.blackShades.medium };
    case 2:
      return { icon: BuildIcon, bgColor: theme.palette.blackShades.medium };
    case 3:
      return { icon: EmojiEventsIcon, bgColor: theme.palette.blackShades.medium };
    case 4:
      return { icon: SavingsIcon, bgColor: theme.palette.blackShades.medium };
    case 5:
      return { icon: NatureIcon, bgColor: theme.palette.blackShades.medium };
    case 6:
      return { icon: GavelIcon, bgColor: theme.palette.blackShades.medium };
    case 7:
      return { icon: SentimentSatisfiedIcon, bgColor: theme.palette.blackShades.medium };
    case 8:
      return { icon: TrendingUpIcon, bgColor: theme.palette.blackShades.medium };
    default:
      return { icon: DesignServicesIcon, bgColor: theme.palette.blackShades.medium };
  }
};

function DevelopersMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
  const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();

  useEffect(() => {
    const currentSentinel = sentinelRef.current;

    if (!currentSentinel) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );

    observer.observe(currentSentinel);

    return () => {
      observer.unobserve(currentSentinel);
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100vw", // ensure full viewport width
        overflowX: "hidden", // prevent horizontal cutoff
        minHeight: "100vh",
        padding: theme.spacing(2), // use uniform padding instead of theme.spacing(1,4)
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(40,40,40,1) 35%, rgba(60,60,60,1) 100%)",
        color: theme.palette.silverShades.light,
      }}
    >
      <Helmet>
        <title>Developers - Meterminds</title>
        <meta name="description" content="Utility metering solutions tailored for property developers." />
        <meta name="keywords" content="developer utilities, metering solutions, property developer metering, utility projects" />
      </Helmet>

      <Box sx={{ mt: theme.spacing(10), textAlign: "center", px: theme.spacing(2) }}>
        <Typography variant="h4" sx={{ fontSize: "1.6rem", fontWeight: "bold", color: "#fff", mb: 2 }}>
          Benefits for Developers
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={() => navigate("/services/utility-sub-metering")}
          sx={{
            position: "absolute",
            left: "-5px",
            top: "-100px",
            color: isScrolled ? theme.palette.silverShades.dark : theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: isScrolled ? theme.palette.bronzeShades.bronze3 : theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        <Box
          sx={{
            position: "absolute",
            bottom: "-75vh",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {popups.map((popup, index) => {
        const { icon: IconComponent, bgColor } = getCardDetails(index, theme);

        return (
          <StyledSlide key={index}>
            <StyledCardBox bgColor={bgColor} aria-hidden="true">
              <CardIcon icon={IconComponent} />
            </StyledCardBox>

            <Box sx={{ width: "100%" }}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.bronzeShades.bronze1,
                  fontWeight: "bold",
                  textShadow: "1px 1px 2px black",
                  mb: 2,
                  px: theme.spacing(2),
                  fontSize: "1.6rem",
                }}
              >
                {popup.title}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.silverShades.light,
                  textShadow: "1px 1px 2px black",
                  px: theme.spacing(2),
                  fontSize: "1.2rem",
                }}
              >
                {popup.content}
              </Typography>
            </Box>
          </StyledSlide>
        );
      })}
    </Box>
  );
}

export default DevelopersMobile;
