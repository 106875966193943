// src/pages/register/RegisterPage.jsx

import React, { useState } from "react";
import { Box, Button, Typography, Paper, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useMediaQuery } from "@mui/material";

import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

// import backgroundImage from "../../../assets/electricity/hero2.jpg";
import { useDispatch } from "react-redux";
import { openLoginModal } from "../../../redux/slices/uiSlice";

import RegisterIndividual from "./RegisterIndividual";
import RegisterCompany from "./RegisterCompany";
import RegisterAdmin from "./RegisterAdmin";

import RegisterCompanyMobile from "./RegisterCompanyMobile";
import RegisterIndividualMobile from "./RegisterIndividualMobile";
import RegisterAdminMobile from "./RegisterAdminMobile";

const StyledContainer = styled(Box)(({ theme }) => ({
  height: "95vh", // Full viewport height
  width: "100vw", // Full viewport width
  position: "fixed",
  top: "3rem",
  left: 0,

  backgroundColor: "rgb(0, 0, 0)", // Fallback background color
  backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(41, 41, 41, 1) 100%)",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
}));

const FormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: "60vw",
  width: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.6)", // Transparent background for glass effect
  backdropFilter: "blur(10px)", // Adds blur for the frosted glass effect
  border: "1px solid rgba(0, 0, 0, 1)", // Light border to simulate glass edges
  color: theme.palette.silverShades.light,
  boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.4)`, // Soft shadow for depth
  borderRadius: "16px",
}));

const MobileContainer = styled(Box)(({ theme }) => ({
  height: "100vh",
  maxWidth: "99vw",
  backgroundColor: "#e0e0e0",
  boxShadow: "inset 8px 8px 16px #bebebe, inset -8px -8px 16px #ffffff",
  overflowY: "auto",
  padding: theme.spacing(2),
}));

const RegisterPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const handleLoginClick = () => dispatch(openLoginModal());

  const [userType, setUserType] = useState("");

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  if (isMobile) {
    return (
      <MobileContainer>
        {userType === "" && (
          <>
            <Box display="flex" alignItems="center" justifyContent="center" mb={3} mt={8}>
              <PersonAddIcon sx={{ fontSize: "3.5rem", color: "#666", mr: 1 }} />
              <Typography variant="h4" sx={{ fontSize: "2.2rem", color: "#333" }}>
                Register
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mb={1}>
              <Typography variant="h6" sx={{ fontSize: "1.6rem", color: "#333" }}>
                Already have an account?
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mb={3}>
              <Button
                onClick={handleLoginClick}
                sx={{
                  fontSize: "1.5rem",
                  color: "#666",
                  textTransform: "none",
                }}
              >
                Login
              </Button>
            </Box>
            <Box width="90%" mb={1}>
              <FormControl fullWidth variant="outlined" >
                <InputLabel
                  id="user-type-label"
                  sx={{
                    fontSize: "1.6rem",
                    color: "#333",
                  }}
                >
                  User Type
                </InputLabel>
                <Select
                  labelId="user-type-label"
                  id="user-type"
                  value={userType}
                  label="User Type"
                  onChange={handleUserTypeChange}
                  sx={{
                    fontSize: "1.6rem",
                    color: "#333",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ccc",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#aaa",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#aaa",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "2rem",
                      color: "#333",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="individual" sx={{ fontSize: "1.5rem" }}>
                    Individual
                  </MenuItem>
                  <MenuItem value="company" sx={{ fontSize: "1.5rem" }}>
                    Company
                  </MenuItem>
                  <MenuItem value="admin" sx={{ fontSize: "1.5rem" }}>
                    Admin
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {userType === "" && (
              <Box width="90%" mb={3}>
                <Typography variant="body2" sx={{ fontSize: "1.4rem", color: "#888" }}>
                  Please select a user type.
                </Typography>
              </Box>
            )}
          </>
        )}
        {userType === "individual" && <RegisterIndividualMobile onBackToUserTypeSelection={() => setUserType("")} />}
        {userType === "company" && <RegisterCompanyMobile onBackToUserTypeSelection={() => setUserType("")} />}
        {userType === "admin" && <RegisterAdminMobile onBackToUserTypeSelection={() => setUserType("")} />}
      </MobileContainer>
    );
  }

  return (
    <StyledContainer>
      <FormPaper elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {userType === "" && (
            <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <PersonAddIcon
                  sx={{
                    fontSize: "3rem",
                    color: theme.palette.bronzeShades.bronze2,
                  }}
                />
                <Typography variant="h5" gutterBottom sx={{ marginLeft: theme.spacing(1) }}>
                  Register
                </Typography>
              </Box>
              <Typography variant="body2">
                Already have an account?{" "}
                <Button
                  onClick={handleLoginClick}
                  sx={{
                    color: theme.palette.bronzeShades.bronze2,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "transparent",
                      textDecoration: "underline",
                    },
                  }}
                >
                  Login
                </Button>
              </Typography>
            </Box>
          )}

          <FormControl
            fullWidth
            margin="normal"
            variant="outlined"
            error={userType === ""}
            sx={{
              "& .MuiFormHelperText-root": {
                color: theme.palette.bronzeShades.bronze3, // Custom color for helper text when there's an error
              },
            }}
          >
            <InputLabel
              id="user-type-label"
              sx={{
                color: theme.palette.silverShades.light,
                "&.Mui-focused": {
                  color: theme.palette.silverShades.light,
                },
                "&.Mui-error": {
                  color: theme.palette.bronzeShades.bronze3, // Custom color for label when in error state
                },
              }}
            >
              User Type
            </InputLabel>
            <Select
              labelId="user-type-label"
              id="user-type"
              value={userType}
              label="User Type"
              onChange={handleUserTypeChange}
              sx={{
                color: theme.palette.silverShades.light,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.bronzeShades.bronze1,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.bronzeShades.bronze2,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.bronzeShades.bronze2,
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.bronzeShades.bronze3, // Custom border color when there's an error
                },
                "& .MuiSvgIcon-root": {
                  color: theme.palette.silverShades.light,
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="company">Company</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
            {userType === "" && <FormHelperText sx={{ color: `${theme.palette.bronzeShades.bronze1} !important` }}>Please select a user type.</FormHelperText>}
          </FormControl>

          {userType === "individual" && <RegisterIndividual onBackToUserTypeSelection={() => setUserType("")} />}
          {userType === "company" && <RegisterCompany onBackToUserTypeSelection={() => setUserType("")} />}
          {userType === "admin" && <RegisterAdmin onBackToUserTypeSelection={() => setUserType("")} />}
        </Box>
      </FormPaper>
    </StyledContainer>
  );
};

export default RegisterPage;
