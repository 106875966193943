import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomButton from "../../../../components/fields/CustomButton";

import DesignServicesIcon from "@mui/icons-material/DesignServices";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import InsightsSharpIcon from "@mui/icons-material/InsightsSharp";

import { Helmet } from "react-helmet";

const StyledSlide = styled(Box)(({ theme }) => ({
  position: "relative",
  maxwidth: "100%",
  backdropFilter: "blur(6px)",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  color: theme.palette.silverShades.light,
  textAlign: "left",
  padding: theme.spacing(2),
  borderTop: `1px inset ${theme.palette.silverShades.light}`,
  marginBottom: "25px",
  overflow: "hidden",
  marginRight: "25px",
}));
const StyledCardBox = styled(Box)(({ theme, bgColor }) => ({
  position: "absolute",
  top: "-15px",
  left: "-15px",
  height: "80px",
  width: "80px",
  borderRadius: "50%",
  backgroundColor: bgColor || theme.palette.blackShades.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: `0 4px 6px rgba(0,0,0,0.1)`,
  zIndex: 1,
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "60px",
    top: "-15px",
    right: "-15px",
  },
}));
const CardIcon = styled(({ icon: IconComponent, ...other }) => <IconComponent {...other} />)(({ theme }) => ({
  color: theme.palette.silverShades.dark,
  fontSize: "36px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

const StyledContentBox = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  textAlign: "left",
  overflowY: "auto",
  // height: "calc(100% - 100px)",
  /* Custom scrollbar styles */
}));

const popups = [
  {
    title: "Overview",
    content: "Installing a bulk meter next to your main municipal meter provides a crucial way to verify the accuracy of your utility charges. By measuring the total consumption of your property, the bulk meter allows you to compare this data with what the municipal meter records. This side-by-side analysis helps identify discrepancies, ensuring that you are not overpaying for your utility services.",
    image: require("../../../../assets/submetering/submetering.jpg"),
    animation: require("../../../../assets/animations/bulkMetering/bulkMetering1.json"),
  },
  {
    title: "Benefits",
    content: "Our online comparison tool takes this a step further. You can easily upload your utility bill to the platform, where it calculates the difference between the readings from your submeters and the main utility account. This detailed comparison reveals whether you are under-recovering on your utility account, giving you valuable insights into your consumption patterns and billing accuracy. With this information, you can take informed steps to address any discrepancies and optimize your utility costs, ensuring that you only pay for what you actually use.",
    image: require("../../../../assets/submetering/submetering.jpg"),
    animation: require("../../../../assets/animations/bulkMetering/bulkMetering2.json"),
  },
  {
    title: "Key Advantages",
    content: [
      {
        subtitle: "1. Cost Efficiency",
        text: "Large complexes often have significant utility expenses. By accurately tracking total consumption through the bulk meter, property managers can ensure they are not overcharged by the municipality. This can lead to substantial savings over time.",
      },
      {
        subtitle: "2. Enhanced Accountability",
        text: "With submetering, individual units can be monitored, promoting accountability among residents. When consumption is accurately measured and compared against the bulk meter, it helps identify any discrepancies that may indicate inefficiencies or leaks.",
      },
      {
        subtitle: "3. Data-Driven Decisions",
        text: "The online comparison tool provides actionable insights by comparing utility bills with submeter data. This allows property managers to make informed decisions about resource management, helping to identify patterns and areas for improvement.",
      },
      {
        subtitle: "4. Dispute Resolution",
        text: "Accurate data helps resolve disputes with utility providers swiftly. If discrepancies arise, property managers can present concrete evidence from the bulk meter, making it easier to negotiate corrections and adjustments.",
      },
      {
        subtitle: "5. Improved Resource Management",
        text: "By understanding usage patterns, property managers can implement conservation initiatives and encourage residents to reduce consumption, further driving down costs and enhancing sustainability efforts.",
      },
      {
        subtitle: "6. Increased Transparency",
        text: "Residents appreciate transparency in billing. By accurately tracking and comparing utility usage, property managers can build trust with tenants, leading to higher satisfaction and retention rates.",
      },
      {
        subtitle: "7. Compliance and Reporting",
        text: "Keeping precise records of utility consumption aids in regulatory compliance and simplifies reporting for property management, ensuring that all necessary documentation is readily available.",
      },
    ],
    image: require("../../../../assets/submetering/submetering.jpg"),
    animation: require("../../../../assets/animations/bulkMetering/bulkMetering3.json"),
  },
];
const getCardDetails = (index, theme) => {
  switch (index % 3) {
    case 0:
      return { icon: LocalShippingSharpIcon, bgColor: theme.palette.blackShades.medium };
    case 1:
      return { icon: InsightsSharpIcon, bgColor: theme.palette.blackShades.medium };
    case 2:
      return { icon: RocketLaunchIcon, bgColor: theme.palette.blackShades.medium };
    default:
      return { icon: DesignServicesIcon, bgColor: theme.palette.blackShades.medium };
  }
};

function BulkMeteringMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonTop, setButtonTop] = useState("150px");
  // const isScrolled = buttonTop === "80px";
  const sentinelRef = useRef(null);
  const { contentRef } = useOutletContext();

  useEffect(() => {
    const currentSentinel = sentinelRef.current; // Capture the current ref

    if (!currentSentinel) return; // Exit if ref is not assigned

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setButtonTop("80px");
        } else {
          setButtonTop("150px");
        }
      },
      { root: null, threshold: 0 }
    );

    observer.observe(currentSentinel); // Use captured ref

    return () => {
      observer.unobserve(currentSentinel); // Use captured ref
      observer.disconnect(); // Clean up the observer
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "rgb(20, 20, 20)",
        backgroundImage: "linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(40, 40, 40, 1) 35%, rgba(60, 60, 60, 1) 100%)",
        color: theme.palette.silverShades.light,
        width: "100vw", // ensure full viewport width
        overflowX: "hidden", // prevent horizontal cutoff
        minHeight: "100vh",
        padding: theme.spacing(2),
      }}
    >
      <Helmet>
        <title>Bulk Metering - Meterminds</title>
        <meta name="description" content="Installing a bulk meter next to your main municipal meter provides a crucial way to verify the accuracy of your utility charges." />
        <meta name="keywords" content="bulk metering, utility verification, municipal meter, utility accuracy, bulk meter installation" />
      </Helmet>

      <Box sx={{ mt: theme.spacing(8), textAlign: "center", px: theme.spacing(2) }}>
        <Typography variant="h4" sx={{ fontSize: "1.6rem", fontWeight: "bold", color: "#fff", mb: 2 }}>
          Bulk Metering
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed", // Changed to 'fixed'
          top: buttonTop,
          left: 0,
          right: 0,
          zIndex: 1000,
          transition: "top 0.3s ease-in-out",
        }}
      >
        <IconButton
          onClick={() => navigate("/")}
          sx={{
            position: "absolute",
            left: "-5px",
            top: "-90px",
            color: theme.palette.bronzeShades.bronze1,
            backgroundColor: "transparent",
            "&:hover": {
              color: theme.palette.bronzeShades.bronze5,
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        {/* Scroll Progress Bar */}

        {/* "Get in Touch" Button */}
        <Box
          sx={{
            position: "absolute",
            bottom: "-75vh",
          }}
        >
          <CustomButton link="/contact-us" text="Get in Touch" />
        </Box>
      </Box>

      {popups.map((popup, index) => {
        const { icon, bgColor } = getCardDetails(index, theme);
        return (
          <StyledSlide
            key={index}
            tabIndex={0}
            onClick={() => navigate(popup.link)}
            onKeyDown={(e) => {
              if (e.key === "Enter") navigate(popup.link);
            }}
          >
            {/* Decorative Box in Top Right */}
            <StyledCardBox bgColor={bgColor} aria-hidden="true">
              <CardIcon icon={icon} />
            </StyledCardBox>

            <Box sx={{ width: "100%" }}>
              {/* Left Column */}

              <Typography
                variant={isSmallScreen ? "h5" : "h4"}
                sx={{
                  color: theme.palette.silverShades.light,
                  fontWeight: "bold",
                  textShadow: "1px 1px 2px black",
                  mb: 2,
                  textAlign: "center",
                  px: theme.spacing(4),
                  fontSize: "1.6rem",
                }}
              >
                {popup.title}
              </Typography>
              {Array.isArray(popup.content) ? (
                <StyledContentBox>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      textAlign: "left",
                    }}
                  >
                    {popup.content.map((item, idx) => (
                      <Box key={idx} sx={{ mb: 2 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: theme.palette.bronzeShades.bronze1,
                            fontWeight: "bold",
                            textShadow: "1px 1px 2px black",
                            fontSize: "1.2rem",
                            mb: 1,
                          }}
                        >
                          {item.subtitle}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1rem",
                            color: theme.palette.silverShades.dark,
                            textShadow: "1px 1px 2px black",
                          }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </StyledContentBox>
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    px: theme.spacing(2),
                    fontSize: "1.2rem",
                    color: theme.palette.silverShades.dark,
                    maxWidth: "100%",
                    textShadow: "1px 1px 2px black",
                  }}
                >
                  {popup.content}
                </Typography>
              )}
            </Box>
          </StyledSlide>
        );
      })}
    </Box>
  );
}

export default BulkMeteringMobile;
