// src/redux/initialState.js
export const initialState = {
  customers: [],
  complexes: [],
  tenants: [],
  customer_documents: [],
  transactions: [],

  customer: {},
  complex: {},
  meter: {},
  tenant: {},
  customer_representative: {},
  customer_communication: [],

  management_fee:{},
  fixed_fee:{},
  arrears_fee:{},

  card__meter_details: {
    connected_meters: 0,
    disconnected_meters: 0,
  },

  processed__customer_documents: [],
  processed__list_meter:[],//drop down for adding a meter

  loading: false,
  error: null,
};
