import React from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation, Link as RouterLink } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const RegisterButton = () => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <Button
      component={RouterLink}
      to="/register"
      sx={{
        position: "fixed",
        top: "50%",
        right: "20px",
        transform: "translateY(-50%)",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 3),
        borderRadius: "12px",
        backgroundColor:
          location.pathname === "/register"
            ? theme.palette.bronzeShades.bronze2
            : theme.palette.bronzeShades.bronze1,
        color: theme.palette.silverShades.light,
        fontWeight: "bold",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        textTransform: "none",
        transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
        "&:hover": {
          backgroundColor: theme.palette.bronzeShades.bronze3,
          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.3)",
          color: theme.palette.blackShades.dark,
        },
        zIndex: 1300,
      }}
    >
      <PersonAddIcon sx={{ marginRight: 1, fontSize: "1.2rem" }} />
      Register
    </Button>
  );
};

export default RegisterButton;
